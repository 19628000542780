import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  Tooltip,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons';
import { FaArrowRight } from 'react-icons/fa'
import { formatPrice } from '../cart/PriceTag'



const OrderSummaryItem = (props) => {
  const { label, value, children } = props
  return (
    <Flex justify="space-between" >
      <Text fontWeight="600" fontSize="md">
        {label}
      </Text>
      {value ? <Text fontWeight="600">{value}</Text> : children}
    </Flex>
  )
}

const Hint = ({ content }) => {
  return (
    <Tooltip label={content} fontSize="md">
      <Box as="span" cursor="pointer" color="gray.500">
        <InfoIcon />
      </Box>
    </Tooltip>
  );
};

function CheckoutOrderSummary({ totalPrice, shippingPrice, shipping_return, handleCheckout }) {
  const isShippingReturn = shipping_return > 0;
  const hintLabel = 'W przypadku rezerwacji do dostawy doliczany jest koszt kuriera do zwrotu';

  return (
    <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full">
      <Stack direction='row' justifyContent='space-between'>
        <Heading size="md">Podsumowanie</Heading>
        {
          isShippingReturn && <Hint content={hintLabel}/>
        }
      </Stack>

      <Stack spacing="6">
        {<OrderSummaryItem label="Wartość koszyka" value={formatPrice(totalPrice)} />}
        {<OrderSummaryItem label="Dostawa" value={formatPrice(shippingPrice + shipping_return)} />}

        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Razem
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(totalPrice + shippingPrice + shipping_return)}
          </Text>
        </Flex>
      </Stack>
      <Button  size="lg" fontSize="md" bgColor='#ffffff' textColor='black' rightIcon={<FaArrowRight />} boxShadow='2px 2px 8px 0 rgba(0,0,0,0.2)' onClick={() => handleCheckout()}>
        Zamów i zapłać
      </Button>
    </Stack>
  )
}

export default CheckoutOrderSummary;