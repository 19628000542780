import { CloseButton, Flex, Select, Link, useColorModeValue as mode, useToast, useColorModeValue } from '@chakra-ui/react'
import { PriceTag } from './PriceTag'
import { CartProductMeta } from './CartProductMeta'
import { useState } from 'react'

const QuantitySelect = ({ stock, selected, onChange }) => {
    return (
        <Select
            maxW="64px"
            aria-label="Select quantity"
            focusBorderColor={useColorModeValue('white.500', 'white.200')}
            defaultValue={selected}
            onChange={(e) => onChange(e)}
        >
            {Array.from({ length: stock }).map((_, index) => (

                <option value={index + 1} key={index}>{index + 1}</option>
            ))}
        </Select>
    )
}

function CartItem({ cartItem, reloadCart, removeFromCart }) {
    const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
    const toast = useToast();

    const removeItemFromCart = async () => {
        setIsDeleteInProgress(true);
        try {
            const requestOptions = {
                method: 'DELETE',
            };

            let response = await fetch(process.env.REACT_APP_API_URI + '/cart/' + cartItem.id, requestOptions);
            if (!response.ok) {
                throw new Error("Wystąpił błąd");
            }


            setIsDeleteInProgress(false);
            removeFromCart();
            toast({
                title: 'Usunięto produkt.',
                description: "Produkt został usunięty.",
                status: 'success',
                duration: 4000,
                isClosable: true,
            });

            reloadCart();
        }
        catch (error) {
            setIsDeleteInProgress(false);
            toast({
                title: 'Wystąpił błąd.',
                description: "Nie udało się usunąć produktu.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    }

    const onStockChange = async (e) => {
        try {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ quantity: e.currentTarget.value })
            };

            let response = await fetch(process.env.REACT_APP_API_URI + '/cart/' + cartItem.id, requestOptions);
            if (!response.ok) {
                const resp_error = await response.json();
                if (resp_error.error) {
                    toast({
                        title: 'Wystąpił błąd.',
                        description: resp_error.error.text,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    return;
                }
                throw new Error();
            }

        }
        catch (error) {
            return;
        }
    }

    const isReservation = cartItem.product_reservation !== null;

    return (
        <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
            <CartProductMeta
                name={cartItem.product.name}
                description={cartItem.product_size.size}
                image={process.env.REACT_APP_API_URI + cartItem.product.product_images[0].image}
                reservation={cartItem.product_reservation}
            />

            {/* Desktop */}
            <Flex width="full" justify="space-between" display={{ base: 'none', md: 'flex' }}>

                <PriceTag price={cartItem.product_details.price} />
                {!isDeleteInProgress && <CloseButton aria-label={`Delete ${cartItem.name} from cart`} onClick={() => removeItemFromCart()} />}
            </Flex>

            {/* Mobile */}
            <Flex
                mt="4"
                align="center"
                width="full"
                justify="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Link fontSize="sm" textDecor="underline" onClick={() => removeItemFromCart()}>
                    Usuń
                </Link>

                <PriceTag price={cartItem.product_details.price} />
            </Flex>
        </Flex>
    )
}


export default CartItem;