import { useEffect, useState } from 'react';

const useGetProductsForCategory = (category_id, rent_view, get_all, page, product_no) => {
    const [products, setProducts] = useState(null);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allPages, setAllPages] = useState(1);
    
    useEffect(() => {
        const getProductsPerCategory = async () => {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ category_id: category_id, rent_only: rent_view, all: get_all, page: page, product_no: product_no})
                };

                let response = await fetch(process.env.REACT_APP_API_URI + '/products_for_category/', requestOptions);
                if (!response.ok) {
                    setError(true);
                    return;
                }

                let response_data = await response.json();
                setProducts(response_data.products);
                setAllPages(response_data.pages);
                setIsLoading(false);
            }
            catch (error) {
            }
        }

        getProductsPerCategory();
    }, [category_id, rent_view, get_all, page]);

    return { products, error, isLoading, allPages };
};

export default useGetProductsForCategory;