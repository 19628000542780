import { Box, useMediaQuery } from "@chakra-ui/react";
import MenuLargeScreen from "./MenuLargeScreen";
import MenuSmallScreen from "./MenuSmallScreen";

function Menu() {
    const [isLargeScreen] = useMediaQuery("(min-width: 768px)");
    return (
        <Box bgColor='#ffffff' boxShadow='0 4px 8px 0 rgba(0,0,0,0.2)'>
            {isLargeScreen ?
                (<MenuLargeScreen />)
                :
                (<MenuSmallScreen/>)}
        </Box>
    );
}

export default Menu;