import { Box, Button, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../cart/PriceTag'


export default function ProductCard({ product, price }) {
    const navigate = useNavigate();
    return (
        <Stack boxShadow='0 4px 8px 0 rgba(0,0,0,0.2)'  marginLeft={2} mr={2} textAlign='center' justifyContent='center' alignItems='center' mb={2}>
            <Image src={process.env.REACT_APP_API_URI + product.product_images[0].image} alt="product image" />
            <Heading mt={1}>{product.name}</Heading>
            <Text>{product.brand}</Text>
            <Text fontWeight='bold'>{formatPrice(price)}</Text>
            <Button m={3} bgColor='#ffffff' textColor='black' boxShadow='2px 2px 8px 0 rgba(0,0,0,0.2)' onClick={() => { navigate(`/product/${product?.id}`) }}>Zobacz</Button>
        </Stack>
    );
}