import {
    Box, Text, Heading, Center, Spinner, useToast, Flex, Stack, useColorModeValue as mode,
    FormControl,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    Button
} from '@chakra-ui/react';
import { useCart } from '../../context/CartContext';
import { useEffect, useState } from 'react';
import { formatPrice } from '../cart/PriceTag'
import CheckoutOrderSummary from './CheckoutOrderSummary';
import useCrsfCookie from '../../hooks/useCrsfCookie';

function IsReservationShippingRequired(cartItems) {
    for(const k in cartItems) {
        if(cartItems[k].product_reservation !== null && cartItems[k].product_reservation !== undefined)  {
            return true;
        }
    }

    return false;
}



function Checkout() {
    const [cartItems, setCartItems] = useState(null);
    const [deliveryOptions, setDeliveryOptions] = useState(null);
    const [selectedDelivery, setSelectedDelivery] = useState(0);
    const [rentDeliveryBack, setRentDeliveryBack] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")

    const [point, setPoint] = useState(null);

    useEffect(() => {
        if (typeof window.InpostCallback === 'undefined') {
        window.InpostCallback = function (point) {
            setPoint(point);
        }
        }

        return () => {window.InpostCallback = undefined};
    }, []);

    const { cartSession, csrfCookie } = useCart();
    const toast = useToast();
    console.log(csrfCookie);

    useEffect(() => {
        async function getCart() {
            setIsLoading(true);
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', "X-CSRFToken": csrfCookie},
                    body: JSON.stringify({ sessionId: cartSession }),
                    credentials: 'include'
                };

                let response = await fetch(process.env.REACT_APP_API_URI + '/cart/', requestOptions);
                let response_delivery = await fetch(process.env.REACT_APP_API_URI + '/delivery/', {
                    method: 'GET'
                });
                if (!response.ok || !response_delivery.ok) {
                    throw new Error("Wystąpił błąd");
                }

                let data = await response.json();
                let delivery = await response_delivery.json();

                setCartItems(data);
                setDeliveryOptions(delivery);

                for (let i = 0; i < delivery.length; i++) {
                    if (delivery[i].default) {
                        setSelectedDelivery(i);
                        setRentDeliveryBack(i);
                    }
                }
                setIsLoading(false);
            }
            catch (error) {
                setError(error)
                setIsLoading(false);
                toast({
                    title: 'Wystąpił błąd.',
                    description: "Nie udało się pobrać danych.",
                    status: 'error',
                    duration: 10000,
                    isClosable: true,
                });
            }
        }

        getCart();
    }, []);

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    }

    const handleCityChange = (e) => {
        setCity(e.target.value);
    }

    const handlePhoneNumberChange = (e) => {
        const { value } = e.target;
        // Allow only numeric characters for the zip code
        const formattedValue = value.replace(/[^\d+-\s]/g, "");

        setPhoneNumber(formattedValue);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    // Function to handle zip code input changes
    const handleZipCodeChange = (e) => {
        setZipCode(e.target.value);
    };

    const handleShowInpostMap = () => {
        window.openModal();
    }

    const handleChangeDelivery = (newDeliveryId) => {
        setSelectedDelivery(newDeliveryId - 1);
    }

    const showErrorToast = (errorMsg) => {
        toast({
            title: 'Błąd danych!',
            description: errorMsg,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
    }

    const validateForm = () => {
        if(!name) {
            showErrorToast("Proszę podać imię i nazwisko.")
            return false;
        }

        if(!address) {
            showErrorToast("Proszę podać adres.")
            return false;
        }

        const zipCodePattern = /^\d{2}-\d{3}$/;
        if(!zipCodePattern.test(zipCode)) {
            showErrorToast("Proszę podać prawidłowy kod pocztowy.")
            return false;
        }

        if(!city) {
            showErrorToast("Proszę podać miasto.")
            return false;
        }

        if(!phoneNumber) {
            showErrorToast("Proszę podać numer telefonu.")
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!emailRegex.test(email)) {
            showErrorToast("Proszę podać prawidłowy email.")
            return false;
        }

        if(deliveryOptions[selectedDelivery].is_inpost_parcel_machine && !point) {
            showErrorToast("Proszę wybrać paczkomat.")
            return false;
        }

        return true;
    }

    const handleCheckout = async() => {
        try {
            if(!validateForm()) {
                return;
            }

            const requestData = {
                sessionId: cartSession,
                user: {
                    name: name,
                    address: address,
                    zipCode: zipCode,
                    city: city,
                    phoneNumber: phoneNumber,
                    email: email
                },
                delivery: {
                    id: selectedDelivery+1,
                    parcelMachine: point ? point.name + ' ' + point.address_details.city : ''
                }

            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "X-CSRFToken": csrfCookie},
                body: JSON.stringify(requestData),
                credentials: 'include'
            };

            let response = await fetch(process.env.REACT_APP_API_URI + '/checkout/create-session/', requestOptions);
            if (!response.ok) {
                const resp_error = await response.json();
                if(resp_error.error) {
                    toast({
                        title: 'Wystąpił błąd.',
                        description: resp_error.error.text,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    return;
                }

                throw new Error("Error");
            }

            let data = await response.json();
            window.location.href = data.redirect_url


        }
        catch (error) {
            console.log(error)
            return;
        }
    }

    if (error) {
        return (<Center minH='80vh' m='3'><Text>Nie udało się pobrać danych</Text></Center>);
    }

    if (isLoading) {
        return (<Center minH='80vh' m='3'><Spinner /></Center>);
    }

    const isCartEmpty = cartItems?.length <= 0;

    if (isCartEmpty) {
        return (
            <Box height='100vh'>
                <Heading textAlign='center'> Koszyk jest pusty </Heading>
            </Box>
        );
    }

    let totalPrice = cartItems.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.quantity * currentValue.product_details.price;
    }, 0);

    const shippingPrice = deliveryOptions[selectedDelivery].cost;
    const isReservation = IsReservationShippingRequired(cartItems);
    const shipping_return = isReservation ?  deliveryOptions[rentDeliveryBack].cost : 0;

    return (
        <Box
            maxW={{ base: '3xl', lg: '7xl' }}
            mx="auto"
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '6', md: '8', lg: '12' }}
            minH='80vh'
        >
            <Stack
                direction={{ base: 'column', lg: 'row' }}
                align={{ lg: 'flex-start' }}
                spacing={{ base: '4', md: '8' }}
            >

                <Stack spacing={{ base: '4', md: '5' }} flex="2">
                    <Heading fontSize="xl" fontWeight="bold">
                        Informacje wysyłkowe
                    </Heading>
                    <Stack spacing={{ base: '1', md: '2' }}>
                        <FormControl isRequired>
                            <FormLabel fontSize='md' fontWeight="600"  htmlFor="name" >Imię i nazwisko</FormLabel>
                            <Input type="text" id="name" fontWeight="600"  name="name" size="sm" value={name} required onChange={handleNameChange} />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel fontSize='md' fontWeight="600"  htmlFor="address" >Adres</FormLabel>
                            <Input type="text" id="address" name="address" fontWeight="600"  size="sm" value={address} required onChange={handleAddressChange} />
                        </FormControl>

                        <Stack direction='row' spacing={{ base: '3', md: '6' }}>
                            <FormControl isRequired>
                                <FormLabel fontSize='md' fontWeight="600"  htmlFor="zipCode" >Kod pocztowy</FormLabel>
                                <Input type="text" id="zipCode" fontWeight='600' name="zipCode" size="sm" value={zipCode} required pattern="\d{2}-\d{3}" onChange={handleZipCodeChange} />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel fontSize='md' fontWeight="600"  htmlFor="city" >Miasto</FormLabel>
                                <Input type="text" id="city" name="city" fontWeight="600"  size="sm" value={city} required onChange={handleCityChange} />
                            </FormControl>

                        </Stack>

                        <FormControl isRequired>
                            <FormLabel fontSize='md' fontWeight="600"  htmlFor="phone">Numer telefonu</FormLabel>
                            <Input type="tel" id="phone" name="phoneNumber" fontWeight="600"  size="sm" value={phoneNumber} required pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}" onChange={handlePhoneNumberChange} />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel fontSize='md' fontWeight="600"  htmlFor="email">Email</FormLabel>
                            <Input type="email" id="email" name="email" fontWeight="600"  size="sm" value={email} required pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" onChange={handleEmailChange} />
                        </FormControl>


                        <RadioGroup mt='2'
                         onChange={handleChangeDelivery} value={selectedDelivery + 1} defaultValue={rentDeliveryBack + 1}
                        >
                            <Stack direction="column" spacing={{ base: '1', md: '2' }}>
                                {deliveryOptions.map((delivery, _) => (
                                    
                                    <Radio value={delivery.id} key={delivery.id}>
                                        <Box mt={2}>
                                            <Stack direction="row" spacing={{ base: '1', md: '2' }} alignItems='center'>
                                                <Text fontSize='md' fontWeight="600" >{delivery.provider}</Text>
                                                <Text fontSize="md" fontWeight="600">{formatPrice(delivery.cost)}</Text>
                                                {delivery.is_inpost_parcel_machine && <Button bgColor='#ffffff' textColor='black' size='sm' onClick={() => handleShowInpostMap()}>{point ? point.name + ' ' + point.address_details.city : <p>Wybierz punkt</p>}</Button>}
                                            </Stack>
                                        </Box>
                                    </Radio>
                                ))}
                            </Stack>
                        </RadioGroup>
                    </Stack>
                </Stack>

                <Flex direction="column" align="center" flex="1">
                    <CheckoutOrderSummary totalPrice={+totalPrice} shippingPrice={+shippingPrice} shipping_return={+shipping_return} handleCheckout={handleCheckout}/>
                </Flex>
            </Stack>
        </Box>
    );
}

export default Checkout;