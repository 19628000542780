import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Text, Center, Spinner, Flex } from "@chakra-ui/react";
import useGetCategories from '../../hooks/useGetCategories';


function CategoryCarousel({ selectedCategory, onCategoryChange }) {
    const { categories, errorCat, isCatLoading } = useGetCategories();


    if (errorCat || !categories) {
        return (<></>);
    }

    if (isCatLoading) {
        return (<Center m='3'><Spinner /></Center>);
    }

    const categoryCarouselBreakpoints = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: categories.length > 6 ? 6 : categories.length
        },
        tabletExt: {
            breakpoint: { max: 1023, min: 765 },
            items: categories.length > 3 ? 3 : categories.length
        },
        mobile: {
            breakpoint: { max: 764, min: 0 },
            items: categories.length > 3 ? 3 : categories.length
        }
    };

    const categoryList = categories.map((item) => (
        <Flex key={item.id}>
            <Box
                
                p={4}
                m={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                _hover={{cursor:'pointer'}}

                onClick={() => onCategoryChange(item.id)}
            >
                <Text textAlign="center" textDecoration={item.id === selectedCategory ? 'underline' : 'none'} fontWeight='bold'>{item.name}</Text>
            </Box>
        </Flex>
    ));


    return (
        <Box width='100%' alignContent='center' justifyContent='center' alignSelf='center' justifySelf='center'
        boxShadow="0px 3px 10px rgba(0, 0, 0, 0.1)">
            <Carousel responsive={categoryCarouselBreakpoints}
                autoPlay={false} infinite={false}>
                {categoryList}
            </Carousel>
        </Box>
    );

}


export default CategoryCarousel;