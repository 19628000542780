import { useState } from "react";
import { Text, Container, Image, Flex, Drawer, IconButton, useDisclosure, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, VStack, Stack } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useCart } from "../../context/CartContext";

function MenuSmallScreen() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showMenu, setShowMenu] = useState(false);
    const {cartItems} = useCart();

    return (
        <Stack direction='row' justifyContent='space-evenly'>
            <Container >
                <Link to="/">
                    <Image src='./logo_nav.png' alt="Logo" />
                </Link>
            </Container>
            <Container justifyContent='center' display='flex' flexDirection='column'>
                <IconButton icon={<HamburgerIcon />}
                    aria-label="Open menu"
                    backgroundColor="#ffffff"
                    textColor='black'
                    size='lg'
                    onClick={onOpen}
                    display={{ base: "block", md: "none" }}
                    alignSelf='flex-end'
                    mr={6}

                //borderRadius='30px'
                //boxShadow='3px 3px 3px 3px rgba(248, 205, 8, .5)'
                />

                <Drawer placement='right' onClose={onClose} isOpen={isOpen}>
                    <DrawerOverlay>
                        <DrawerContent maxW='50%' backgroundColor='#ffffff'>
                            <DrawerCloseButton color='black' />
                            <DrawerHeader></DrawerHeader>
                            <DrawerBody>
                                <VStack spacing={4}>
                                    <Link to="/buy"><Text as='h2' size='lg' color='black'>Kup</Text></Link>
                                    <Link to="/rent"><Text as='h2' size='lg' color='black'>Wypożycz</Text></Link>
                                    <Link to="/cart">
                                        <Stack direction='row' justifyContent='space-evenly'>
                                            <Text as='h2' size='lg' color='black'>Koszyk</Text>
                                            {cartItems > 0 && <Text color='red'>{cartItems}</Text>}
                                        </Stack>
                                    </Link>
                                </VStack>
                            </DrawerBody>

                        </DrawerContent>
                    </DrawerOverlay>

                </Drawer>
            </Container>
        </Stack>
    );
}


export default MenuSmallScreen;