import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';



function Success() {
    return (
        <Box minHeight="100vh" display="flex" justifyContent="center" alignItems="center" >
            <Flex direction="column" alignItems="center" p={10} bg="white" borderRadius="md" boxShadow="lg">
                <Icon as={CheckCircleIcon} w={12} h={12} color="green.400" mb={4} />
                <Text fontSize="2xl" fontWeight="bold" mb={2}>
                    Płatność potwierdzona
                </Text>
                <Text fontSize="md" textAlign="center">
                    Dziękujemy za zakupy!
                </Text>
            </Flex>
        </Box>
    );
}

export default Success;