
import { Button, useToast, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useCart } from '../../context/CartContext';
import useCrsfCookie from '../../hooks/useCrsfCookie';

function toISOLikeString(d) {
    return `${
      d.getFullYear()
    }-${
      `${d.getMonth() + 1}`.padStart(2, '0')
    }-${
      `${d.getDate()}`.padStart(2, '0')
    }T${
      `${d.getHours()}`.padStart(2, '0')
    }:${
      `${d.getMinutes()}`.padStart(2, '0')
    }:${
      `${d.getSeconds()}`.padStart(2, '0')
    }Z`;
  }


function ReservationCalendar({ product_details_id, product_size }) {

    const [disabledDates, setDisabledDates] = useState();
    const [addToCartIsLoading, setAddToCartIsLoading] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const toast = useToast();
    const { cartSession, AddItemToCart, csrfCookie } = useCart();

    useEffect(() => {
        async function getProductReservation() {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ product_details_id: product_details_id, product_size_id: product_size.id }),
                };

                let response = await fetch(process.env.REACT_APP_API_URI + '/product_reservation/', requestOptions);
                if (!response.ok) {
                    throw new Error("Wystąpił błąd");
                }
                let data = await response.json();
                setDisabledDates(data);

            }
            catch (error) {
                return;
            }
        };
        getProductReservation();
    }, [product_details_id, product_size])

    const handleAddReservation = async() => {
        if (selectedDateRange === null) {
            toast({
                title: 'Nieprawidłowy data.',
                description: "Proszę wybrać datę rezerwacji.",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setAddToCartIsLoading(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "X-CSRFToken": csrfCookie},
                body: JSON.stringify({ sessionId: cartSession, product_details_id: product_details_id, product_size: product_size.id,
                    begin_on: selectedDateRange.begin_on, end_on: selectedDateRange.end_on
                 }),
                 credentials: 'include'
            };

            let response = await fetch(process.env.REACT_APP_API_URI + '/add_product_to_cart/', requestOptions);
            if (!response.ok) {
                const resp_error = await response.json();
                if(resp_error.error) {
                    toast({
                        title: 'Wystąpił błąd.',
                        description: resp_error.error.text,
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                    });
                    setAddToCartIsLoading(false);
                    return;
                }
                throw(new Error());
            }

            setAddToCartIsLoading(false);
            AddItemToCart();
            toast({
                title: 'Dodano produkt.',
                description: "Dodano do koszyka.",
                status: 'success',
                duration: 4000,
                isClosable: true,
            });
        }
        catch (error) {
            setAddToCartIsLoading(false);
            toast({
                title: 'Wystąpił błąd.',
                description: "Proszę spróbować później.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    }


    const parseDateToString = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return date;
    }


    const tileDisabled = ({ date }) => {
        if (disabledDates === undefined) {
            return false;
        }

        return disabledDates.some(period => {
            const begin = parseDateToString(period.begin_on);
            const end = parseDateToString(period.end_on);
            return date >= begin && date <= end;
        });

    };



    const handleSelectRange = (value) => {
        setSelectedDateRange({begin_on: toISOLikeString(value[0]), end_on: toISOLikeString(value[1])});
    };

    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 3);

    return (
        <Stack direction='column' alignItems='center'>
            <Calendar
                minDate={currentDate}
                view='month'
                maxDate={new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, currentDate.getDate())}
                tileDisabled={tileDisabled}
                selectRange
                onChange={handleSelectRange}
            />

            <Button variant='solid' bgColor='#ffffff' onClick={handleAddReservation}>Dodaj rezerwację</Button>
        </Stack>
    );
}

export default ReservationCalendar;