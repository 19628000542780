import { Box, List, ListItem, OrderedList, UnorderedList, Text } from "@chakra-ui/react";


function PurchaseTerms() {

    return (
        <Box m={3}>
            <OrderedList fontWeight='bold'>
                <ListItem>Regulamin</ListItem>
                <OrderedList>
                    <ListItem>Właścicielem i prowadzącym sklep internetowy za pomocą witryny  https:// www.madle.pl  (zwanym dalej „Sklepem internetowym” lub „Sklepem”) jest Monika Cichoń, prowadząca działalność nierejestrowaną pod nazwą MADLE pod adresem Zaczarnie 312, 33-140 Lisia Góra</ListItem>
                    <ListItem> Niniejszy Regulamin skierowany jest do wszystkich użytkowników oraz określa zasady dokonywania rejestracji i korzystania z Konta indywidualnego, sprzedaży produktów Sprzedawcy za pośrednictwem Sklepu internetowego oraz określa zasady i warunki świadczenia nieodpłatnych usług drogą elektroniczną.</ListItem>
                    <ListItem>Klient ma możliwość kontaktu ze Sklepem internetowym pod adresem email: c.madle2024@gmail.com lub telefonicznie od poniedziałku do piątku w godzinach 10:00 – 18:00   pod numerem telefonu +48 781526508</ListItem>
                    <ListItem>Administratorem danych osobowych Klientów jest Monika Cichoń, prowadząca działalność nierejestrowaną pod nazwą MADLE pod adresem Zaczarnie 312, 33-140 Lisia Góra</ListItem>
                </OrderedList>
            </OrderedList>

            <Box as="br" /><Box as="br" /><Box as="br" />

            <OrderedList fontWeight='bold'>
                <ListItem >Definicje</ListItem>
                <Text>
                    Klient – podmiot będący użytkownikiem strony internetowej Sklepu, na rzecz którego zgodnie z Regulaminem i przepisami prawa mogą być świadczone usługi za pośrednictwem Sklepu internetowego.<Box as="br" />
                    Kodeks Cywilny - ustawa kodeks cywilny z dnia 23 kwietnia 1964 r. (Dz.U. z 2014 r., poz. 121 ze zm.).<Box as="br" />
                    Konsument – oznacza osobę fizyczną dokonującą z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową.<Box as="br" />
                    Konto indywidualne – panel przypisany indywidualnie do Klienta po rejestracji danych w systemie Sklepu internetowego, oznaczony adresem e-mail i hasłem podanym przez Klienta w systemie teleinformatycznym Sprzedawcy, pozwalający na korzystanie przez Klienta z dodatkowych funkcjonalności strony internetowej Sklepu.<Box as="br" />
                    Przedsiębiorca - oznacza osobę fizyczną, osobę prawną lub jednostkę organizacyjną niebędącą osobą prawną, której ustawa przyznaje zdolność prawną, prowadzącą we własnym imieniu działalność gospodarczą lub zawodową i dokonującą czynności prawnej związanej bezpośrednio z jej działalnością gospodarczą lub zawodową.<Box as="br" />
                    Regulamin - oznacza niniejszy regulamin.<Box as="br" />
                    Sprzedawca -  Firma MADLE, Zaczarnie 312, 33-140 Lisia Góra<Box as="br" />
                    Towar – oznacza produkt przedstawiany każdorazowo przez Sprzedawcę w ramach Sklepu internetowego, w celu jego sprzedaży.<Box as="br" />
                    Dane kontaktowe – następujące dane, za pomocą których Klient ma możliwość skontaktowania się ze Sklepem internetowym: adres email: c.madle2024@gmail.com. lub telefonicznie od poniedziałku do piątku w godzinach 10:00 – 18:00   pod numerem telefonu +48 781526508<Box as="br" />
                    Umowa Sprzedaży – umowa sprzedaży Towarów zawarta pomiędzy Sprzedawcą a Klientem, której warunki określa w szczególności niniejszy Regulamin. Umowa Sprzedaży jest zawierana z wykorzystaniem środków porozumiewania się na odległość, po akceptacji Zamówienia przez Sprzedawcę na zasadach określonych w niniejszym Regulaminie.<Box as="br" />
                </Text>
                <Box as="br" />

                <ListItem>Postanowienia ogólne i zasady korzystania ze Sklepu</ListItem>
                <OrderedList>
                    <ListItem>Dostęp do Regulaminu każdy Klient może uzyskać w dowolnym momencie, poprzez  odnośnik „Regulamin sklepu” umieszczony na stronie Sklepu internetowego, a także poprzez zapisanie jego wersji PDF na wybranym przez siebie nośniku .</ListItem>
                    <ListItem>Informacje o Towarach zamieszczone w Sklepie internetowym, takie jak zdjęcia, opisy, ceny stanowią zaproszenie do zawarcia umowy sprzedaży w rozumieniu art. 71 Kodeksu Cywilnego, zgodnie z warunkami Regulaminu.</ListItem>
                    <ListItem>Zdjęcia i opisy oferowanych produktów służą prezentacji konkretnie wskazanych na nich modeli Towarów.</ListItem>
                    <ListItem>Minimalne wymagania techniczne, których spełnienie jest niezbędne do współpracy z systemem teleinformatycznym, którym posługuje się Sprzedawca, w tym zawarcia Umowy Sprzedaży i świadczenia innych usług elektronicznych, to: (1) komputer, laptop lub inne urządzenie multimedialne z dostępem do Internetu; (2) dostęp do poczty elektronicznej i ważny adres e-mail; (3) przeglądarka internetowa: Mozilla Firefox w wersji min. 17.0 lub Internet Explorer w wersji min. 10.0, Opera w wersji min. 12.0, Google Chrome w wersji min. 23.0., Safari w wersji min. 5.0; (4) zalecana minimalna rozdzielczość ekranu to 1024x768 .</ListItem>
                    <ListItem>Nie jest dopuszczalne:</ListItem>
                    <OrderedList styleType='lower-alpha'>
                        <ListItem>korzystanie ze Sklepu internetowego w celu prowadzenia działalności, która naruszałaby interes właściciela Sklepu internetowego, w sposób sprzeczny z prawem, dobrymi obyczajami lub naruszający dobra osobiste osób trzecich,</ListItem>
                        <ListItem>dostarczanie do Sklepu treści bezprawnych,</ListItem>
                        <ListItem>korzystanie ze Sklepu w sposób zakłócający jego funkcjonowanie lub  podejmowanie czynności informatycznych lub wszelkich innych czynności mających na celu wejście w posiadanie informacji nieprzeznaczonych dla Klienta, w tym danych innych Klientów;</ListItem>
                        <ListItem>umieszczanie w ramach Sklepu niezamówionej informacji handlowej, oraz</ListItem>
                        <ListItem>korzystanie z treści umieszczonych na stronie Sklepu do innego użytku niż użytek własny.</ListItem>
                    </OrderedList>
                </OrderedList>
                <Box as="br" />

                <ListItem>Zasady składania Zamówienia i zawierania Umowy Sprzedaży</ListItem>
                <OrderedList>
                    <ListItem>Sprzedawca umożliwia zawieranie umów sprzedaży Towarów za pośrednictwem sieci Internet oraz świadczy inne usługi przewidziane w niniejszym Regulaminie.</ListItem>
                    <ListItem>W celu rejestracji Konta indywidualnego oraz w celu zawarcia Umowy Sprzedaży za pośrednictwem strony internetowej Sklepu, konieczne jest posiadanie przez Klienta aktywnego konta poczty elektronicznej.</ListItem>
                    <ListItem>Utworzenie Konta indywidualnego nie jest konieczne do złożenia zamówienia w Sklepie internetowym.</ListItem>
                    <ListItem>Klient może składać zamówienia w Sklepie Internetowym za pośrednictwem strony internetowej Sklepu przez 7 dni w tygodniu, 24 godziny na dobę.</ListItem>
                    <ListItem>W celu złożenia zamówienia należy dokonać wyboru Towarów w Sklepie, w szczególności w zakresie ich modeli, ilości oraz rozmiarów. Każdy z Towarów należy dodać do „Koszyka” za pomocą opcji „Dodaj do Koszyka”. Wybór każdego z Towarów spowoduje przejście do ”Koszyka”. Po skompletowaniu całości zamówienia należy przejść do „Koszyka” i kontynuować procedurę składania zamówienia. Realizując zamówienie Klient może zalogować się do Konta indywidualnego bądź realizować zamówienie bez rejestracji, uzupełniając swoje dane osobowe oraz adres dostawy w odpowiednich polach. Klient w kolejnych krokach ma możliwość wyboru sposobu dostawy Towarów i metody płatności oraz jest informowany o łącznej cenie za wybrany Towar i jego dostawę, jak też o wszystkich dodatkowych kosztach, jakie jest zobowiązany ponieść w związku z zamówieniem.</ListItem>
                    <ListItem>Następnie, Klient składa zamówienie przechodząc do opcji „Kupuję i płacę”. </ListItem>
                    <ListItem>Warunkiem złożenia zamówienia jest akceptacja Regulaminu oraz Polityki Prywatności poprzez oznaczenie odpowiedniego pola w formularzu zamówienia.</ListItem>
                    <ListItem>Informacje podane przez Klienta w toku składania zamówienia powinny być zgodne z prawdą, aktualne oraz dokładne. Sprzedawca zastrzega sobie możliwość odmowy realizacji zamówienia, w przypadku, gdy podane dane są na tyle niedokładne, że uniemożliwiają realizacje zamówienia, w szczególności uniemożliwiają prawidłowe dostarczenie zamówionego Towaru. Przed odmową realizacji zamówienia Sprzedawca podejmie próbę kontaktu z Klientem w celu ustalenia niezbędnych danych. </ListItem>
                    <ListItem>Do momentu zatwierdzenia wyboru Towarów przyciskiem „Kupuję i płacę” Klient ma możliwość dokonywania zmian i modyfikacji Towarów w zamówieniu, jak i danych teleadresowych do wysyłki lub faktury. Do momentu dokonania płatności Klient może zaprzestać składania zamówienia poprzez niekontynuowanie kolejnych kroków. W takiej sytuacji zamówienie Klienta nie zostanie zrealizowane.</ListItem>
                    <ListItem>Złożenie przez Klienta zamówienia stanowi ofertę Klienta złożoną Sprzedawcy do zawarcia umowy sprzedaży Towarów w rozumieniu przepisów Kodeksu Cywilnego. </ListItem>
                    <ListItem>Po złożeniu zamówienia, Sprzedawca przesyła na podany przez Klienta adres poczty elektronicznej potwierdzenie złożenia zamówienia.Następnie, Sprzedawca przesyła na podany przez Klienta adres poczty elektronicznej informację o przyjęciu zamówienia do realizacji. </ListItem>
                    <ListItem>Zawarcie Umowy Sprzedaży Towarów pomiędzy Sprzedawcą a Klientem następuje wraz z otrzymaniem przez Klienta informacji o przyjęciu zamówienia, o którym mowa w ust. 11. Informacja ta zawiera potwierdzenie warunków Umowy Sprzedaży zawartej na podstawie Regulaminu. </ListItem>
                </OrderedList>
                <Box as="br" />


                <ListItem>Utworzenie Konta indywidualnego</ListItem>
                <OrderedList>
                    <ListItem>W celu utworzenia Konta indywidualnego, Klient obowiązany jest dokonać nieodpłatnej rejestracji swoich danych w bazie Sklepu. Rejestracja danych odbywa się poprzez wypełnienie formularza rejestracyjnego dostępnego w zakładce „Twoje zamówienia – zarejestruj się”. Wypełnienie formularza wymaga podania następujących danych: imienia, nazwiska, adresu e-mail oraz ustalenia indywidualnego dla Klienta hasła. </ListItem>
                    <ListItem>Warunkiem utworzenie Konta indywidualnego jest akceptacja Regulaminu oraz Polityki Prywatności poprzez oznaczenie odpowiedniego pola w formularzu.</ListItem>
                    <ListItem>Po przesłaniu wypełnionego formularza rejestracyjnego Klient otrzymuje niezwłocznie, drogą elektroniczną na adres poczty elektronicznej podany w formularzu rejestracyjnym potwierdzenie rejestracji Konta indywidualnego w Sklepie przez Sprzedawcę. Z tą chwilą zawarta zostaje umowa o świadczenie drogą elektroniczną usługi Konta indywidualnego, zaś Klient uzyskuje możliwość dostępu do Konta indywidualnego i dokonywania zmian swoich danych podanych w momencie rejestracji.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dostawa</ListItem>
                <OrderedList>
                    <ListItem>Towary dostarczane są na wskazany przez Klienta w zamówieniu adres na terenie Rzeczypospolitej Polskiej.</ListItem>
                    <ListItem>Towary dostarczane są za pośrednictwem firmy kurierskiej  InPOST na terenie Polski. Opłaty za dostawę Towarów są podawane w ramach formularza zamówienia. Sprzedawca zastrzega możliwość przesłania Towarów za pośrednictwem innej firmy kurierskiej, w celu usprawnienia dostawy Towarów do Klienta.</ListItem>
                    <ListItem>W dniu nadania Towaru do Klienta Sprzedawca wysyła na adres poczty elektronicznej Klienta informację o wysłaniu przesyłki.</ListItem>
                    <ListItem>Towary dostarczane są  w terminie od 2 do 4 dni roboczych. W przypadku podania terminu dostawy w dniach roboczych należy rozumieć pod tym pojęciem wszystkie dni od poniedziałku do piątku włącznie, z wyjątkiem dni ustawowo wolnych od pracy.</ListItem>
                    <ListItem>W ramach każdej przesyłki Towaru znajduje się dowód zakupu (paragon lub faktura Vat)</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Ceny i Płatności</ListItem>
                <OrderedList>
                    <ListItem>Ceny na stronie podawane w złotych polskich są cenami brutto, zawierają podatek VAT.</ListItem>
                    <ListItem>Do ceny towaru należy doliczyć koszt przesyłki, którego wysokość uzależniona jest od wybranej formy dostawy i płatności.</ListItem>
                    <ListItem>Cena zakupu towaru podana na stronie Sklepu jest ostateczna i ma charakter wiążący od momentu otrzymania przez Klienta wiadomości elektronicznej z potwierdzeniem przyjęcia złożonego przez Klienta zamówienia zakupu wybranych Towarów, wskazanego w § 3 ust. 10. Cena ta nie zmieni się, niezależnie od wprowadzonych na stronę internetową Sklepu zmian cen lub rozpoczętych akcji promocyjnych lub wyprzedażowych.</ListItem>
                    <ListItem>Klient płaci cenę za zamówione Towary wraz z kosztami dostawy przelewem bankowym za pośrednictwem płatności elektronicznej realizowanej w systemie płatniczym Przelewy 24, obsługiwanym przez grupę DialCom24 Sp. z o.o. </ListItem>
                    <ListItem>Sprzedawca zastrzega sobie prawo zmiany cen Towarów znajdujących się w Sklepie, wprowadzania nowych Towarów do Sklepu, przeprowadzania i odwoływania akcji promocyjnych na stronach sklepu, bądź wprowadzania w nich zmian zgodnie z Kodeksem cywilnym i innymi ustawami, przy czym zmiany takie nie naruszają praw osób, które zawarły Umowy Sprzedaży Towarów oferowanych przez Sklep przed dokonaniem ww. zmian lub praw osób uprawnionych do korzystania z danej promocji, zgodnie z jej zasadami i w okresie jej trwania.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Reklamacje</ListItem>
                <OrderedList>
                    <ListItem>Jeżeli zakupiony Towar ma wadę fizyczną lub prawną, Sprzedawca odpowiada względem Klienta na zasadzie art. 556 i następnych Kodeksu cywilnego (rękojmia).</ListItem>
                    <ListItem>Sprzedawca podejmuje działania w celu zapewnienia w pełni poprawnego funkcjonowania Sklepu, w takim zakresie, jaki wynika z aktualnej wiedzy technicznej i zobowiązuje się usunąć w rozsądnym terminie wszelkie nieprawidłowości zgłoszone przez Klientów.</ListItem>
                    <ListItem>Jeżeli sprzedany Towar ma wadę, Klient może:</ListItem>
                    <OrderedList styleType='lower-alpha'>
                        <ListItem>złożyć oświadczenie o obniżeniu ceny albo odstąpieniu od Umowy Sprzedaży, chyba że Sprzedawca niezwłocznie i bez nadmiernych niedogodności dla Klienta wymieni Towar wadliwy na wolny od wad albo wadę usunie. Ograniczenie to nie ma zastosowania, jeżeli Towar był już wymieniony lub naprawiany przez Sprzedawcę albo Sprzedawca nie uczynił zadość obowiązkowi wymiany Towaru na wolny od wad lub usunięcia wad.</ListItem>
                        <ListItem>żądać wymiany Towaru na wolny od wad albo usunięcia wady. Sprzedawca jest obowiązany wymienić Towar wadliwy na wolny od wad lub usunąć wadę w rozsądnym czasie bez nadmiernych niedogodności dla Klienta.</ListItem>
                    </OrderedList>
                    <ListItem>Klient może zamiast zaproponowanego przez Sprzedawcę usunięcia wady żądać wymiany Towaru na wolny od wad albo zamiast wymiany Towaru żądać usunięcia wady, chyba że doprowadzenie rzeczy do zgodności z umową w sposób wybrany przez Klienta jest niemożliwe albo wymagałoby nadmiernych kosztów w porównaniu ze sposobem proponowanym przez Sprzedawcę. Przy ocenie nadmierności kosztów uwzględnia się wartość Towaru wolnego od wad, rodzaj i znaczenie stwierdzonej wady, a także bierze się pod uwagę niedogodności, na jakie narażałby Klienta inny sposób zaspokojenia.</ListItem>
                    <ListItem>Sprzedawca może odmówić zadośćuczynienia żądaniu Klienta co do wymiany Towaru na wolny od wad albo usunięcia wady, jeżeli doprowadzenie do zgodności z Umową Sprzedaży Towaru wadliwego w sposób wybrany przez Klienta jest niemożliwe lub w porównaniu z drugim możliwym sposobem doprowadzenia do zgodności z Umową Sprzedaży wymagałoby nadmiernych kosztów. Koszty naprawy lub wymiany ponosi Sprzedawca.</ListItem>
                    <ListItem>Klient, który wykonuje uprawnienia z tytułu rękojmi, jest obowiązany dostarczyć rzecz wadliwą na adres Sprzedawcy. Koszt dostarczenia pokrywa Sprzedawca.</ListItem>
                    <ListItem>Reklamację można złożyć na piśmie poprzez odesłanie towaru wraz z opisem wady i dowodem zakupu w Sklepie, listem poleconym lub inną formą przesyłki, na adres MADLE Monika Cichoń, Zaczarnie 312, 33-140 Lisia Góra lub skorzystać z formularza reklamacji dostępnego w zakładce „Reklamacje i zwroty”, a  jeśli reklamacja dotyczy nieodpłatnych usług świadczonych drogą elektroniczną przez Sprzedawcę, o których mowa w Regulaminie. Reklamację można złożyć na adres poczty elektronicznej Sprzedawcy, z zawarciem opisu zgłaszanego problemu. </ListItem>
                    <ListItem>Klient otrzyma informację o sposobie rozpatrzenia reklamacji w terminie 14 dni od dnia zgłoszenia żądania przez Klienta.</ListItem>
                    <ListItem>Sprzedawca nie zobowiązuje się w ramach niniejszego Regulaminu do korzystana z pozasądowego rozwiązywania sporów, o których mowa w ustawie z dnia 23 września 2016 r. o pozasądowym rozwiązywaniu sporów konsumenckich.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Prawo odstąpienia od Umowy Sprzedaży</ListItem>
                <OrderedList>
                    <ListItem>Klient będący Konsumentem, który zawarł Umowę Sprzedaży, może w terminie 14 dni odstąpić od niej bez podawania przyczyny.</ListItem>
                    <ListItem>Bieg terminu na odstąpienie od Umowy Sprzedaży rozpoczyna się od chwili objęcia Towaru w posiadanie przez Konsumenta lub wskazaną przez niego osobę trzecią.</ListItem>
                    <ListItem>Konsument może odstąpić od Umowy Sprzedaży informując Sklep o swojej decyzji o odstąpieniu od umowy w drodze jednoznacznego oświadczenia, na przykład wysłanego pisemnie na adres MADLE Monika Cichoń, Zaczarnie 312, 33-140 Lisia Góra lub za pośrednictwem poczty elektronicznej na adres Sprzedawcy podany w Danych kontaktowych. </ListItem>
                    <ListItem>Klient może skorzystać ze wzoru formularza, znajdującego się w zakładce „Reklamacje i zwroty” jednak nie jest to obowiązkowe. Klient może również wypełnić i przesłać skanem formularz lub jakiekolwiek inne jednoznaczne oświadczenie za pośrednictwem poczty elektronicznej na adres Sprzedawcy podany w Danych kontaktowych. W przypadku złożenia przez Klienta oświadczenia o odstąpieniu drogą elektroniczną, Sprzedawca  niezwłocznie prześle Klientowi na trwałym nośniku potwierdzenie otrzymania tego oświadczenia.</ListItem>
                    <ListItem>Do zachowania terminu określonego w ust. 1 wystarczy wysłanie oświadczenia o odstąpieniu przed jego upływem. </ListItem>
                    <ListItem>W przypadku odstąpienia od Umowy Sprzedaży, jest ona uważana za niezawartą.</ListItem>
                    <ListItem>Jeśli Konsument złożył oświadczenie o odstąpieniu od Umowy Sprzedaży zanim Sprzedawca przyjął jego ofertę, oferta przestaje wiązać.</ListItem>
                    <ListItem>Sprzedawca ma obowiązek niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od Umowy Sprzedaży, zwrócić mu dokonane przez niego płatności, za wyjątkiem kosztu dostarczenia Towaru. Sprzedawca może wstrzymać się ze zwrotem płatności otrzymanych od Konsumenta do chwili otrzymania z powrotem Towaru lub dostarczenia przez Klienta dowodu odesłania Towaru, w zależności od tego, które zdarzenie nastąpi wcześniej. Sprzedawca dokonuje zwrotu płatności przy użyciu takiego samego sposobu zapłaty, jakiego użył Konsument chyba, że Konsument wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.</ListItem>
                    <ListItem>Konsument ma obowiązek zwrócić Towar Sprzedawcy niezwłocznie, jednak nie później niż w terminie 14 dni od dnia, w którym odstąpił od Umowy Sprzedaży. Do zachowania terminu wystarczy odesłanie Towaru przed jego upływem.</ListItem>
                    <ListItem>W wypadku odstąpienia od Umowy Sprzedaży, Klient będący Konsumentem ponosi bezpośrednie koszty zwrotu Towaru.</ListItem>
                    <ListItem>Konsument ponosi odpowiedzialność za zmniejszenie wartości Towaru będące wynikiem korzystania z niego w sposób wykraczający poza sposób konieczny do stwierdzenia charakteru, cech i funkcjonowania Towaru.</ListItem>
                    <ListItem>Warunkiem przyjęcia zwrotu towaru jest dostarczenie oryginalnego dowodu zakupu.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Inne usługi świadczone drogą elektroniczną</ListItem>
                <OrderedList>
                    <ListItem>Sprzedawca, oprócz umożliwienia zawierania za pośrednictwem Sklepu Umów Sprzedaży zgodnie z niniejszym Regulaminem, świadczy również nieodpłatnie drogą elektroniczną następujące usługi na rzecz Klientów:</ListItem>
                    <OrderedList styleType='lower-alpha'>
                        <ListItem>Usługę Konta indywidualnego,</ListItem>
                        <ListItem>Usługę Powiadamiania o dostępności Towaru i Zapisu na Towar</ListItem>
                        <ListItem>Wypożyczalnie sukienek (osobny regulamin)</ListItem>
                    </OrderedList>
                    <ListItem>Usługa Konta indywidualnego dostępna jest po dokonaniu rejestracji danych w systemie Sklepu na zasadach opisanych w paragrafie 4 Regulaminu i polega na udostępnieniu Klientowi indywidualnego panelu w systemie teleinformatycznym Sprzedawcy, pozwalającego Klientowi korzystać z dodatkowych funkcjonalności strony internetowej Sklepu, takich jak modyfikacja danych Klienta podtrzymywanie sesji Klienta po zalogowaniu się przez niego do Konta indywidualnego, przechowywanie i udostępnianie Klientowi historii zamówień, śledzenie statusu zamówień.</ListItem>
                    <ListItem>Usługa Powiadamiania o dostępności Towaru i Zapisu na Towar dostępna jest po uzupełnieniu odpowiedniego pola adresem e-mail. </ListItem>
                    <ListItem>Klient uprawniony jest w każdym momencie do żądania zaprzestania świadczenia przez Sklep usług wskazanych w ust. 1. W takim wypadku umowa o świadczenie odpowiednio usług Newslettera bądź usługi Konta indywidualnego rozwiązuje się, a Sprzedawca ma 14 dni na odpowiednio usunięcie Konta indywidualnego bądź usunięcie danych Klienta z bazy danych osób, które wyraziły zgodę na otrzymywanie Newslettera, z zastrzeżeniem zasad przetwarzania danych osobowych Klientów zawartych w dokumencie „Polityka Prywatności”, dostępnej na stronie internetowej Sklepu pod zakładką „Polityka Prywatności”.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Ochrona danych osobowych</ListItem>
                <OrderedList>
                    <ListItem>Dane osobowe Klienta są przetwarzane przez Sprzedawcę jako administratora danych osobowych.</ListItem>
                    <ListItem>Sprzedawca stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę danych osobowych.</ListItem>
                    <ListItem>Zasady i cele przetwarzania danych osobowych Klientów zawarte są w dokumencie „Polityka Prywatności”, dostępnej na stronie internetowej Sklepu pod zakładką „Polityka Prywatności”.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Postanowienia końcowe</ListItem>
                <OrderedList>
                    <ListItem>Regulamin obowiązuje od dnia 1.06.2024 r. </ListItem>
                    <ListItem>Umowy zawierane przez Sprzedawcę zawierane są w języku polskim.</ListItem>
                    <ListItem>Sprzedawca ponosi odpowiedzialność z tytułu niewykonania lub nienależytego wykonania umowy. W przypadku umów zawieranych z Klientami będącymi Przedsiębiorcami Sprzedawca ponosi odpowiedzialność tylko w przypadku umyślnego wyrządzenia szkody i w granicach rzeczywiście poniesionych strat przez Klienta będącego Przedsiębiorcą.</ListItem>
                    <ListItem>Strony będą dążyły do polubownego rozwiązania wszelkich sporów powstałych na gruncie umowy zawartej na podstawie niniejszego Regulaminu.</ListItem>
                    <ListItem>Klientowi będącemu Konsumentem przysługuje możliwość skorzystania z następujących, pozasądowych sposobów  dochodzenia roszczeń oraz rozpatrywania reklamacji: </ListItem>
                    <OrderedList styleType='lower-alpha'>
                        <ListItem>możliwość zwrócenia się z wnioskiem o przeprowadzenie postępowania w sprawie pozasądowego rozwiązywania sporów konsumenckich na podstawie Ustawy z dnia 23 września 2016 r. o pozasądowym rozwiązywaniu sporów konsumenckich (Dz. U. z 2016 r., poz. 1823), tj. np. do Wojewódzkiego Inspektora Inspekcji Handlowej w Gdańsku;</ListItem>
                        <ListItem>wystąpienie z wnioskiem o rozpoznanie sprawy przez Stały Polubowny Sąd Konsumencki działający przy odpowiednim Wojewódzkim Inspektoracie Inspekcji Handlowej; oraz</ListItem>
                        <ListItem>zwrócenie się do Miejskiego Rzecznika Praw Konsumentów o pomoc w zakresie ochrony interesów i praw konsumentów. Bezpłatną pomoc konsumentom w zakresie ochrony ich praw i interesów świadczą także organizacje społeczne, takie jak np. Stowarzyszenie Konsumentów Polskich.</ListItem>
                    </OrderedList>
                    <ListItem>Brak akceptacji Regulaminu uniemożliwia zakup Towarów oferowanych przez Sklep. Sklep umożliwi Klientowi zapoznanie się z Regulaminem przy składanym zamówieniu. Wszystkie zamówienia przyjęte przez Sprzedawcę do realizacji przed dniem wejścia w życie nowego Regulaminu są realizowane na podstawie Regulaminu, który obowiązywał w dniu składania zamówienia przez Klienta. Klienci posiadający Konto indywidualne zostaną poinformowani o zmianie Regulaminu za pośrednictwem korespondencji e-mail. Klientowi, który nie akceptuje wprowadzonych w Regulaminie zmian przysługuje prawo odstąpienia od umowy świadczenia usług drogą elektroniczną.</ListItem>
                    <ListItem>Prawem właściwym dla rozstrzygania wszelkich sporów powstałych na gruncie niniejszego Regulaminu jest prawo polskie. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie powszechnie obowiązujące przepisy prawa polskiego, w szczególności: Kodeksu Cywilnego oraz inne właściwe przepisy powszechnie obowiązującego prawa.</ListItem>
                </OrderedList>


            </OrderedList>
        </Box>
    );
}

export default PurchaseTerms;