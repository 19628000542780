import {
  Box,
  Image,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'

const parseDateToString = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return `${day}/${month}/${year}`;
}


export const CartProductMeta = (props) => {
  const { image, name, description, reservation } = props
  const isReservation = reservation !== null;
  return (
    <Stack direction="row" spacing="5" width="full">
      <Image
        rounded="lg"
        width="120px"
        height="120px"
        fit="cover"
        src={image}
        alt={name}
        draggable="false"
        loading="lazy"
      />
      <Box pt="4">
        <Stack spacing="0.5">
          <Text fontWeight="800">{name}</Text>
          <Text  fontSize="sm">
            {description}
          </Text>
          {isReservation && <Text  fontSize="sm">
            {parseDateToString(reservation.begin_on).toLocaleString()} </Text>
          }

          {
            isReservation && <Text  fontSize="sm">
              {parseDateToString(reservation.end_on).toLocaleString()}
            </Text>
          }

        </Stack>
      </Box>
    </Stack>
  )
}