import { Box, List, ListItem, OrderedList, UnorderedList, Text } from "@chakra-ui/react";


function RentTerms() {

    return (
        <Box m={3}>

            <OrderedList fontWeight='bold'>
                <ListItem>Dział I. DEFINICJE</ListItem>
                <Text>Użyte w Regulaminie pojęcia oznaczają:</Text>
                <UnorderedList>
                    <ListItem>Serwis– serwis internetowy wypożyczania odzieży oraz akcesoriów odzieżowych dostępny pod adresem: http://www.madle.pl, którego właścicielem i administratorem jest Monika Cichoń;</ListItem>
                    <ListItem>MADLE - działalność nierejestrowaną pod nazwą MADLE pod adresem Zaczarnie 312, 33-140 Lisia Góra; właściciel Monika Cichoń</ListItem>
                    <ListItem>Regulamin - niniejszy dokument wraz z wszelkimi ewentualnymi załącznikami i dodatkami, określający zasady świadczenia i korzystania z usług udostępnianych przez MADLE w ramach Serwisu oraz prawa i obowiązki Użytkownika i MADLE;</ListItem>
                    <ListItem>Użytkownik – osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, która korzysta z Serwisu, zawiera z MADLE umowę najmu na podstawie której wypożycza Rzecz za pośrednictwem Serwisu oraz na rzecz której świadczone są drogą elektroniczną przez MADLE inne wskazane w Regulaminie usługi;</ListItem>
                    <ListItem>Konsument - osoba fizyczna dokonująca z MADLE czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową;</ListItem>
                    <ListItem>Przedsiębiorca – osoba fizyczna, osoba prawna lub jednostka organizacyjna, niebędąca osobą prawną, której ustawa przyznaje zdolność prawną, prowadząca we własnym imieniu działalność gospodarczą lub zawodową, dokonująca z MADLE czynności prawnej związanej bezpośrednio z jej działalnością gospodarczą lub zawodową;</ListItem>
                    <ListItem>Dostawca – przewoźnik działający na zlecenie MADLE, za pośrednictwem którego dokonywana jest dostawa oraz zwrot Rzeczy;</ListItem>
                    <ListItem>Konto – dedykowana Użytkownikowi część Serwisu, dostępna dla Użytkownika po zalogowaniu, w której gromadzone są dane Użytkownika, w tym informacje o złożonych przez Niego Zamówieniach;</ListItem>
                    <ListItem>Rejestracja – procedura założenia Konta Użytkownika w Serwisie poprzez wypełnienie formularza Rejestracji;</ListItem>
                    <ListItem>Login – unikalna nazwa Użytkownika podana przez Niego w procesie Rejestracji, wpisywana każdorazowo przez Użytkownika podczas każdego logowania do Konta;</ListItem>
                    <ListItem>Rzecz – rzecz ruchoma - odzież lub akcesorium odzieżowe - która jest oferowana Użytkownikom przez Usługodawcę za pośrednictwem Serwisu i która może być przedmiotem umowy wypożyczenia zawartej pomiędzy Użytkownikiem a MADLE, zgodnie z postanowieniami Regulaminu;</ListItem>
                    <ListItem>Zamówienie – oświadczenie woli Użytkownika składane za pośrednictwem Serwisu, zmierzające bezpośrednio do zawarcia z MADLE umowy, na podstawie której Użytkownik wypożycza Rzecz;</ListItem>
                    <ListItem>Abonament – pakiet usług uprawniający Użytkownika do korzystania z usług wypożyczenia Rzeczy w ramach Serwisu na preferencyjnych warunkach, rozliczany w okresach miesięcznych;</ListItem>
                    <ListItem>Polityka Prywatności – zasady przetwarzania danych osobowych Użytkownika przez MADLE w związku z funkcjonowaniem Serwisu;</ListItem>
                </UnorderedList>
                <Box as="br" />


                <ListItem>Dział II. INFORMACJE OGÓLNE</ListItem>
                <OrderedList>
                    <ListItem>Postanowienia niniejszego Regulaminu określają prawa i obowiązki Użytkowników Serwisu oraz MADLE, a także zasady działania Serwisu oraz świadczenia usług przez MADLE, w szczególności zasady dokonywania Rejestracji i korzystania z Konta w ramach Serwisu, warunki i zasady składania drogą elektroniczną Zamówień w ramach Serwisu, uprawnienia Klienta do rezygnacji z Zamówienia i odstąpienia od zawartej z MADLE umowy, zasady zgłaszania i rozpatrywania reklamacji.</ListItem>
                    <ListItem>Serwis internetowy dostępny pod adresem internetowym http://www.madle.pl prowadzony jest przez MADLE Monika Cichoń.</ListItem>
                    <ListItem>MADLE za pośrednictwem Serwisu świadczy usługę wypożyczenia odzieży i akcesoriów odzieżowych (Rzeczy) oraz inne wskazane w Regulaminie usługi świadczone drogą elektroniczną. Umowa wypożyczenia Rzeczy zawierana w ramach Serwisu jest umową najmu w rozumieniu przepisów ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2018, poz. 1025, z późn. zm.).</ListItem>
                    <ListItem>Zawarcie umowy wypożyczenia Rzeczy nie powoduje przeniesienia przez MADLE na rzecz Użytkownika własności Rzeczy, a jedynie ustanawia prawo do korzystania z Rzeczy przez określony czas na warunkach wskazanych w niniejszym Regulaminie. MADLE pozostaje wyłącznym właścicielem będących przedmiotem wypożyczenia Rzeczy.</ListItem>
                    <ListItem>Użytkownicy mogą skontaktować się z MADLE  zgodnie z niżej podanymi danymi:</ListItem>
                    <Text>e-mail: c.madle2024@gmail.com tel.: +48 781 526 508</Text>
                    <ListItem>W ramach korzystania z Serwisu, Użytkowników obowiązuje zakaz dostarczania treści niezgodnych z obowiązującymi przepisami prawa, sprzecznych z zasadami współżycia społecznego lub dobrymi obyczajami, w tym naruszających dobra osobiste oraz inne prawa osób trzecich. Użytkownik zobowiązany jest do powstrzymywania się od jakiejkolwiek aktywności, która mogłaby negatywnie wpłynąć na prawidłowe funkcjonowanie Serwisu, w tym w szczególności od jakiegokolwiek ingerowania w zawartość Serwisu lub jego elementy techniczne. Zabronione jest ponadto wykorzystywanie Serwisu do celów sprzecznych z jego przeznaczeniem, w tym rozsyłanie spamu, prowadzenie na stronie internetowej Serwisu jakiejkolwiek działalności komercyjnej, reklamowej lub promocyjnej. Użytkownik zobowiązuje się nie naruszać praw własności intelektualnej przysługujących MADLE lub podmiotom trzecim związanych z działalnością Serwisu oraz oferowanymi Rzeczami. Użytkownik zobowiązany jest nie podejmować działań zmierzających do uzyskania w ramach Serwisu dostępu do danych, do którego nie jest uprawniony, w szczególności poprzez naruszenie zabezpieczeń.</ListItem>
                    <ListItem>Informacje na temat przetwarzania danych osobowych oraz wykorzystywania plików cookies w ramach Serwisu znajdują się w Polityce Prywatności dostępnej we właściwej zakładce na stronie internetowej Serwisu.</ListItem>
                    <ListItem>Informacje zawarte na stronie internetowej Serwisu odnoszące się do Rzeczy (w tym w zakresie cen) nie stanowią oferty w rozumieniu ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2018 r. poz. 1025, ze zm.), lecz stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71 Kodeksu cywilnego.</ListItem>
                    <ListItem>MADLE nie ponosi odpowiedzialności za zakłócenia w funkcjonowaniu Serwisu (w tym przerwy w funkcjonowaniu) spowodowane siłą wyższą, niedozwolonym działaniem osób trzecich lub niekompatybilnością Serwisu z infrastrukturą techniczną Użytkownika, w najszerszym dopuszczalnym przez obowiązujące przepisy prawa zakresie.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział III. WARUNKI KORZYSTANIA PRZEZ UŻYTKOWNIKA Z SERWISU</ListItem>
                <OrderedList>
                    <ListItem>Warunkiem rozpoczęcia korzystania z Serwisu jest zapoznanie się z niniejszym Regulaminem i jego akceptacja.</ListItem>
                    <ListItem>Do korzystania z funkcjonalności Serwisu niezbędne jest posiadanie przez Użytkownika urządzenia końcowego z dostępem do sieci Internet (np. komputer, tablet, smartfon) i przeglądarką internetową typu Internet Explorer, Microsoft Edge, Google Chrome, Mozilla Firefox, Opera lub Safari w aktualnej wersji. Do korzystania z niektórych funkcjonalności Serwisu, w tym w celu dokonania Rejestracji, a także składania Zamówień w ramach Serwisu, konieczne jest ponadto posiadanie aktywnego konta poczty elektronicznej (e-mail).</ListItem>
                    <ListItem>Serwis korzysta z plików cookies. Wszelkie informacje dotyczące korzystania z plików cookies przez Serwis znajdują się w Polityce Prywatności dostępnej we właściwej zakładce na stronie internetowej Serwisu.</ListItem>
                    <ListItem>Użytkownikami Serwisu mogą być osoby fizyczne, które są zdolne do zawierania wiążących umów w świetle obowiązującego prawa oraz osoby prawne lub jednostki organizacyjne nieposiadające osobowości prawnej, którym ustawa przyznaje zdolność prawną.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział IV. REJESTRACJA KONTA</ListItem>
                <OrderedList>
                    <ListItem>Do składania Zamówień za pośrednictwem Serwisu konieczne jest utworzenie przez Użytkownika Konta.</ListItem>
                    <ListItem>Konto jest tworzone po przeprowadzeniu przez Użytkownika poprawnego procesu Rejestracji Konta w Serwisie. W ramach Konta gromadzone są dane Użytkownika oraz informacje o Użytkowniku dotyczące czynności podejmowanych przez Niego w Serwisie, w tym informacje o złożonych przez niego Zamówieniach.</ListItem>
                    <ListItem>Świadczenie usługi prowadzenia Konta przez MADLE jest nieodpłatne i bezterminowe.</ListItem>
                    <ListItem>Rejestracja Konta następuje poprzez wypełnienie interaktywnego formularza Rejestracji dostępnego na stronie internetowej Serwisu, postępując według komunikatów wyświetlanych podczas procesu Rejestracji.</ListItem>
                    <ListItem>W procesie Rejestracji Użytkownik potwierdza, że zapoznał się z niniejszym Regulaminem oraz rozumie i akceptuje wszystkie jego postanowienia oraz zobowiązuje się do ich przestrzegania.</ListItem>
                    <ListItem>Po dokonaniu Rejestracji Konta Użytkownik otrzymuje od MADLE wiadomość mailową z informacją o utworzeniu Konta.</ListItem>
                    <ListItem>Użytkownik loguje się do Konta za pośrednictwem strony internetowej Serwisu za pomocą Loginu i hasła, podanych przez Użytkownika w procesie Rejestracji.</ListItem>
                    <ListItem>Hasło może zostać zmienione przez Użytkownika po zalogowaniu na Konto.</ListItem>
                    <ListItem>Użytkownik zobowiązany jest do ochrony hasła i Loginu przed ich pozyskaniem lub wykorzystaniem przez osobę nieuprawnioną. Użytkownik ponosi wyłączną odpowiedzialność za udostępnienie Loginu i hasła osobom trzecim. W celu zapobiegania uzyskania Loginu i hasła przez osoby nieupoważnione, Użytkownik każdorazowo po zakończeniu korzystania z Serwisu, powinien wylogować się z Konta.</ListItem>
                    <ListItem>Utworzenie Konta oznacza zawarcie pomiędzy Użytkownikiem a MADLE umowy o świadczenie usługi drogą elektroniczną na czas nieokreślony, której przedmiotem jest prowadzenia Konta.</ListItem>
                    <ListItem>Użytkownik może wypowiedzieć umowę prowadzenia Konta w każdym czasie, poprzez wysłanie oświadczenia o wypowiedzeniu do MADLE w drodze pisemnej lub za pośrednictwem poczty elektronicznej na adresy wskazane w Dziale II Regulaminu. Niezwłocznie po dotarciu oświadczenia o wypowiedzeniu do MADLE, Konto zostanie usunięte. Użytkownik może również usunąć Konto samodzielnie, po zalogowaniu na Konto, poprzez wybór odpowiednich zakładek w panelu Konta, co jest równoznaczne z wypowiedzeniem umowy o świadczenie usługi Konta.</ListItem>
                    <ListItem>Użytkownik zobowiązany jest do aktualizowania danych Konta w celu zapewnienia ich poprawności.</ListItem>
                    <ListItem>MADLE jest uprawniony do wypowiedzenia umowy o świadczenie usługi Konta w przypadku, gdy Użytkownik korzysta z Serwisu w sposób naruszający przepisy prawa, postanowienia niniejszego Regulaminu, postanowienia zawartej umowy wypożyczenia Rzeczy, prawa osób trzecich lub w sposób zakłócający jego funkcjonowanie. W sytuacjach opisanych w zdaniu powyżej, MADLE może zamiast wypowiedzenia umowy o świadczenie usługi Konta dokonać czasowej blokady korzystanie przez Użytkownika z Konta.</ListItem>
                    <ListItem>MADLE może złożyć oświadczenie o wypowiedzeniu umowy o świadczenie usługi Konta Użytkownika przesyłając Użytkownikowi oświadczenie o wypowiedzeniu umowy o świadczenie usługi Konta na adres poczty elektronicznej Użytkownika aktualnie zarejestrowany w Serwisie.</ListItem>
                    <ListItem>Wypowiedzenie umowy o świadczenie usługi Konta Użytkownika przez którąkolwiek ze Stron, jak również rozwiązanie umowy o świadczenie usługi Konta Klienta za zgodą obu Stron, będzie skutkowało usunięciem Konta Użytkownika z Serwisu.</ListItem>
                    <ListItem>Wypowiedzenie umowy o świadczenie usługi Konta przez którąkolwiek ze Stron, jak również rozwiązanie umowy o świadczenie usługi Konta Użytkownika za zgodą obu Stron, nie ma wpływu na prawa nabyte przez Strony przed wypowiedzeniem lub rozwiązaniem tej umowy.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział V. REALIZACJA ZAMÓWIEŃ</ListItem>
                <OrderedList>
                    <ListItem>Zamówienia są realizowane przez MADLE na terytorium Rzeczypospolitej Polskiej.</ListItem>
                    <ListItem>W celu wypożyczenia Rzeczy Użytkownik składa Zamówienie poprzez stronę internetową Serwisu. Dla złożenia Zamówienia konieczne jest założenie przez Użytkownika Konta w Serwisie.</ListItem>
                    <ListItem>Użytkownik może składać Zamówienia za pośrednictwem strony internetowej Serwisu, 24 godziny na dobę, 7 dni w tygodniu, z tym zastrzeżeniem, że realizacja Zamówień odbywa się w dni robocze. Dniami roboczymi są dni od poniedziałku do piątku, za wyjątkiem dni ustawowo wolnych od pracy.</ListItem>
                    <ListItem>W celu złożenia Zamówienia Użytkownik dokonuje wyboru Rzeczy z dostępnej na stronie internetowej Serwisu oferty poprzez „dodanie” jej do wirtualnego koszyka. Następnie Użytkownik wypełnia formularz Zamówienia, postępując zgodnie z komunikatami wyświetlanymi na stronie internetowej Serwisu.</ListItem>
                    <ListItem>Użytkownik składając Zamówienie, jest zobowiązany do podania prawidłowych i zgodnych z prawdą danych niezbędnych do realizacji Zamówienia. MADLE nie odpowiada za nieprawidłową realizację Zamówienia (w tym niedostarczenie lub opóźnienie w dostarczeniu Rzeczy) wynikającą z przyczyn leżących po stronie Użytkownika, w szczególności z powodu podania przez Użytkownika nieprawidłowych lub niepełnych danych niezbędnych do realizacji Zamówienia.</ListItem>
                    <ListItem>Po złożeniu Zamówienia Użytkownik jest informowany o otrzymaniu Zamówienia przez MADLE za pośrednictwem wiadomości przesłanej na podany przez Użytkownika adres poczty elektronicznej.</ListItem>
                    <ListItem>W kolejnej wiadomości przesłanej na podany przez Użytkownika adres poczty elektronicznej MADLE potwierdza przyjęcie Zamówienia do realizacji. Z chwilą otrzymania przez Użytkownika wiadomości z potwierdzeniem przyjęcia Zamówienia do realizacji, pomiędzy Użytkownikiem i  zostaje zawarta umowa najmu Rzeczy, na podstawie której Użytkownik wypożycza Rzecz od MADLE na określony czas.</ListItem>
                    <ListItem>Użytkownik ma prawo zrezygnować z Zamówienia tylko i wyłącznie przed otrzymaniem od MADLE wiadomości e-mail potwierdzającej przyjęcie Zamówienia do realizacji. W tym celu Użytkownik powinien niezwłocznie powiadomić MADLE o rezygnacji z Zamówienia.</ListItem>
                    <ListItem>MADLE przystępuje do realizacji Zamówienia po zaksięgowaniu płatności z tytułu Zamówienia na rachunku bankowym MADLE.</ListItem>
                    <ListItem>Zamówienia, które zostały przyjęte do realizacji oraz należycie opłacone do godziny 14:00 danego dnia roboczego realizowane są w tym samym dniu. Zamówienia złożone w piątki po godzinie 14:00 realizowane są w pierwszy dzień roboczy przypadający po sobocie i niedzieli. Realizacja Zamówienia rozumiana jest jako przekazanie skompletowanego Zamówienia Dostawcy w celu doręczenia Użytkownikowi na podany przez Niego adres dostawy.</ListItem>
                    <ListItem>Wraz z wypożyczoną Rzeczą Użytkownikowi dostarczone jest potwierdzenie zapłaty drogą e-mailową lub faktura VAT z tytułu zapłaconej ceny wypożyczenia Rzeczy.</ListItem>
                    <ListItem>Okres wypożyczenia Rzeczy wynosi 4 lub 5 dni kalendarzowych, przy czym pierwszym dniem wypożyczenia jest dzień doręczenia (czwartek lub piątek) Rzeczy na podany przez Użytkownika adres dostawy.</ListItem>
                    <ListItem>Użytkownik dokonuje zwrotu wypożyczonej Rzeczy w ostatnim dniu wypożyczenia (poniedziałek). Użytkownik w poniedziałek nadaję paczkę i zwraca wypożyczoną Rzecz. W przypadku zmiany dni wypożyczania Rzeczy, Konsument ma obowiązek skontaktować się ze sklepem MADLE aby ustalić dogodny termin.</ListItem>
                    <ListItem>Przedłużenie okresu wypożyczenia Rzeczy wymaga uzyskania akceptacji MADLE i wiąże się z naliczeniem opłaty za kolejny okres wypożyczenia, która wynosi 40 zł za każdy dzień przedłużenia okresu wypożyczenia.</ListItem>
                    <ListItem>Nieodebranie przez Użytkownika Rzeczy w umówionym terminie, pomimo prawidłowej dostawy zgodnie z Zamówieniem, pozostaje bez wpływu na cenę oraz okres trwania wypożyczenia Rzeczy. W takim wypadku okres wypożyczenia pozostaje niezmieniony i liczony jest od pierwszego dnia wypożyczenia wskazanego w Zamówieniu. Użytkownik w celu ustalenia nowego terminu dostarczenia Rzeczy podejmuje kontakt bezpośrednio z Dostawcą (kurierem).</ListItem>
                    <ListItem>W przypadku, gdy realizacja Zamówienia nie będzie możliwa w terminach wskazanych w punkcie 10 powyżej, Użytkownik zostanie o tym poinformowany poprzez przesłanie wiadomości na podany adres poczty elektronicznej lub telefonicznie. W takiej sytuacji Użytkownik ma prawo:</ListItem>
                    <OrderedList>
                        <ListItem>wybrać inny termin wypożyczenia tej samej Rzeczy, pod warunkiem dostępności wypożyczonej Rzeczy w nowo wybranym terminie.</ListItem>
                        <ListItem>Wybrać inną Rzecz do wypożyczenia, pod warunkiem dostępności wypożyczanej Rzeczy w wybranym przez Użytkownika terminie. W sytuacji istnienia różnicy w cenie wypożyczenia pomiędzy Rzeczą wybraną pierwotnie, a Rzeczą nowo wybraną, Użytkownik będzie w zależności od sytuacji zobowiązany do uiszczenia dopłaty różnicy cen między zamówionymi Rzeczami na konto bankowe MADLE bądź uprawniony do uzyskania od MADLE zwrotu nadpłaty, która zostanie dokonana na kartę podarunkową, chyba że Użytkownik i MADLE postanowią inaczej.</ListItem>
                        <ListItem>Całkowicie zrezygnować z wypożyczenia Rzeczy. W razie rezygnacji MADLE zwraca Użytkownikowi dokonaną przez niego płatność na kartę podarunkową, chyba że Użytkownik i MADLE postanowią inaczej.</ListItem>
                    </OrderedList>
                    <ListItem> W przypadku braku możliwości realizacji Zamówienia z powodu trwałej niedostępności Rzeczy MADLE niezwłocznie, jednak nie później niż w terminie 2 dni od dnia stwierdzenia niedostępności Rzeczy, zawiadamia o tym Użytkownika i zwraca dokonaną przez niego płatność w sposób analogiczny do sposobu dokonania płatności przez Użytkownika, chyba że Użytkownik i MADLE postanowią inaczej.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział VI. CENY I PŁATNOŚĆ</ListItem>
                <OrderedList>
                    <ListItem>Wszystkie ceny prezentowanych na stronie internetowej Serwisu Rzeczy są cenami brutto (zawierają podatek VAT) wyrażonymi w złotych polskich. Ceny te nie zawierają kosztów dostawy Rzeczy.</ListItem>
                    <ListItem>Całkowity koszt Zamówienia obejmujący cenę wypożyczenia Rzeczy oraz koszty dostawy, wskazywany jest w toku składania Zamówienia i jest widoczny dla Użytkownika przed zawarciem umowy wypożyczenia Rzeczy.</ListItem>
                    <ListItem>W ramach Serwisu dostępne są następujące formy płatności z tytułu dokonanych Zamówień:</ListItem>
                    <OrderedList>
                        <ListItem>płatność online kartą debetową/kredytową</ListItem>
                        <ListItem>szybki przelew elektroniczny (Tpay.com)</ListItem>
                    </OrderedList>
                    <ListItem>Brak otrzymania płatności na rachunek bankowy MADLE w terminie 24 godzin od daty złożenia Zamówienia spowoduje anulowanie Zamówienia. O anulowaniu zamówienia Użytkownik zostanie poinformowany za pośrednictwem poczty elektronicznej.</ListItem>
                    <ListItem>MADLE zastrzega sobie prawo do zmiany cen Rzeczy oferowanych w Serwisie oraz przeprowadzania i odwoływania akcji promocyjnych lub wyprzedaży. Uprawnienie, o którym mowa w zdaniu powyżej nie ma wpływu na Zamówienia złożone przed datą wejścia w życie zmiany ceny, warunków akcji promocyjnych lub wyprzedaży.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział VII. DOSTAWA</ListItem>
                <OrderedList>
                    <ListItem>Wyłącznie dostępną formą dostawy zamówionych przez Użytkownika Rzeczy jest wysyłka za pośrednictwem firmy kurierskiej, będącego operatorem usług kurierskich.</ListItem>
                    <ListItem>Użytkownik jest informowany o kosztach dostawy w toku składania Zamówienia, które są widoczne dla Użytkownika przed zawarciem umowy wypożyczenia Rzeczy.</ListItem>
                    <ListItem>Koszty dostawy Rzeczy do Użytkownika w sytuacji, gdy wartość Zamówienia (rozumiana jako cena wypożyczenia Rzeczy bez kosztów ubezpieczenia i kosztów dostawy) nie przekracza kwoty 500,00 złotych ponosi Użytkownik. W przypadku Zamówień, których wartość przekracza kwotę 500,00 złotych, koszty dostawy pokrywa MADLE..</ListItem>
                    <ListItem>Rzecz dostarczana jest na adres dostawy podany przez Użytkownika w procesie składania Zamówienia.</ListItem>
                    <ListItem>Użytkownik zostanie poinformowany o wysłaniu przesyłki zawierającej zamówioną Rzecz przez Dostawcę oraz numerze przesyłki, poprzez przesłanie wiadomości na adres poczty elektronicznej Użytkownika.</ListItem>
                    <ListItem>Użytkownik zobowiązany jest do odebrania Rzeczy dostarczonej zgodnie z Zamówieniem.</ListItem>
                    <ListItem>W przypadku nieodebrania Rzeczy dostarczonej na adres Użytkownika zgodnie z Zamówieniem, w celu ustalenia nowego terminu dostarczenia Rzeczy przez Dostawcę kuriera, Użytkownik podejmuje kontakt bezpośrednio z Dostawcą- kurierem.</ListItem>
                    <ListItem>Na czas dostarczenia wypożyczonej Rzeczy do Użytkownika składa się czas realizacji Zamówienia przez Serwis (skompletowanie i przygotowanie Zamówienia do wysyłki oraz jego przekazanie Dostawcy kurier) oraz czas dostawy wypożyczonej Rzeczy przez Dostawcę kuriera.</ListItem>
                    <ListItem>Czas dostawy wypożyczonej Rzeczy przez Dostawcę wynosi maksymalnie 2 dni robocze od momentu przekazania Zamówienia przez MADLE Dostawcy.</ListItem>
                    <ListItem>MADLE nie odpowiada za nieprawidłowości w dostarczeniu wypożyczonej Rzeczy do Użytkownika - w tym niedostarczenie lub opóźnienie w dostarczeniu wypożyczonej Rzeczy - wynikające z przyczyn leżących po stronie Użytkownika, w szczególności z uwagi na podanie nieprawidłowego adresu dostawy przez Użytkownika.</ListItem>
                    <ListItem>Użytkownik zobowiązany jest niezwłocznie sprawdzić stan przesyłki zawierającej zamówioną Rzecz po jej doręczeniu. W razie stwierdzenia jakichkolwiek uszkodzeń lub ubytków przesyłki, Użytkownik zobowiązany jest niezwłocznie poinformować o tym fakcie Dostawcę, a następnie MADLE oraz spisać właściwy protokół w obecności pracownika Dostawcy potwierdzający nieprawidłowy stan przesyłki.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział VIII. POSTĘPOWANIE Z RZECZĄ PRZEZ UŻYTKOWNIKA ORAZ ODPOWIEDZIALNOŚĆ.</ListItem>
                <OrderedList>
                    <ListItem>Wszystkie oferowane w Serwisie Rzeczy znajdują się w prawidłowym stanie technicznym i estetycznym (rzeczy mogą posiadać drobne ślady użytkowania). Przed wysyłką każda rzecz jest wyprana i gotowa do użytku.</ListItem>
                    <ListItem>Każda wypożyczona Rzecz jest ubezpieczona w zakresie naprawy drobnych uszkodzeń, takich jak uszkodzenie zamka lub oderwanie guzika oraz w zakresie dających się usunąć zabrudzeń. W powyższym zakresie Użytkownik zwolniony jest od odpowiedzialności względem MADLE.</ListItem>
                    <ListItem>Od chwili odbioru wypożyczonej Rzeczy do chwili zwrotu wypożyczonej Rzeczy Użytkownik jest odpowiedzialny względem MADLE z tytułu utraty (w tym kradzieży), uszkodzenia lub zniszczenia Rzeczy.</ListItem>
                    <ListItem>Użytkownik nie może dokonywać samodzielnie, ani zlecać podmiotom trzecim jakichkolwiek ingerencji w wypożyczaną Rzecz, w szczególności:</ListItem>
                    <OrderedList>
                        <ListItem>czyszczenia wypozyczonej Rzeczy w jakimkolwiek zakresie oraz w jakikolwiek sposób, w szczególności ich prania;</ListItem>
                        <ListItem>dokonywania jakichkolwiek poprawek krawieckich wypożyczonej Rzeczy, niezależnie od ich charakteru i zakresu;</ListItem>
                    </OrderedList>
                    <ListItem>W przypadku jakichkolwiek wątpliwości co do stanu wypożyczonej Rzeczy czy też sposobu postępowania z wypożyczoną Rzeczą Użytkownik jest zobowiązany skontaktować się z MADLE przed podjęciem jakichkolwiek czynności.</ListItem>
                    <ListItem>Użytkownik nie jest uprawniony do udostępnienia wypożyczonej Rzeczy osobom trzecim do używania.</ListItem>
                    <ListItem>W przypadku naruszenia przez Użytkownika postanowień punktów 4, 5 lub 6 powyżej, Użytkownik ponosi pełną odpowiedzialność z tytułu ewentualnego uszkodzenia, zniszczenia lub utraty (w tym kradzieży) Rzeczy.</ListItem>
                    <ListItem>Użytkownik zobowiązuje się dbać o wypożyczoną Rzecz oraz utrzymywać ją w należytym stanie technicznym i estetycznym przez okres trwania wypożyczenia.</ListItem>
                    <ListItem>Użytkownik ponosi odpowiedzialność za zużycie wypożyczonej Rzeczy w stopniu wykraczającym ponad zwykłe zużycie wynikające z prawidłowego korzystania z wypożyczonej Rzeczy zgodnie z przeznaczeniem i Regulaminem, jej uszkodzenie, zniszczenie, zdekompletowanie, zaginięcie, utratę (w tym kradzież), jak również dokonanie jakiekolwiek ingerencji w wypożyczoną Rzecz zmieniającej jej pierwotny stan.</ListItem>
                    <ListItem>W przypadku stwierdzenia przez MADLE, iż zwrócona wypożyczona Rzecz znajduje się w nieprawidłowym stanie w stosunku do stanu w jakim została oddana Użytkownikowi do używania, zawiadomi o tym Użytkownika niezwłocznie, nie później niż w terminie 3 dni roboczych od daty doręczenia MADLE zwróconej Rzeczy oraz prześle Użytkownikowi za pośrednictwem poczty elektronicznej opis stwierdzonych nieprawidłowości wraz z ewentualną dokumentacją fotograficzną.</ListItem>
                    <ListItem>Wysokość szkody jest ustalana przez MADLE na podstawie oszacowania kosztów przywrócenia wypożyczonej Rzeczy do prawidłowego stanu. W przypadku zaistnienia niemożliwości przywrócenia wypożyczonej Rzeczy do stanu pierwotnego, utraty (w tym kradzieży) wypożyczonej Rzeczy lub uszkodzeń, których naprawa wiąże się z nadmiernym w stosunku do wartości wypożyczonej Rzeczy nakładem finansowym lub nakładem pracy, Użytkownik ponosi odpowiedzialność w zakresie kosztów nabycia nowej Rzeczy.</ListItem>
                    <ListItem>Użytkownik zostanie powiadomiony o ustalonej przez MADLE wysokości szkody w drodze wiadomości przesłanej na podany adres poczty elektronicznej Użytkownika i jest zobowiązany jest do zapłaty kwoty odszkodowania podanej w zawiadomieniu w terminie 7 dni od daty wysłania wiadomości z zawiadomieniem o wysokości szkody</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział IX. ZWROT RZECZY</ListItem>
                <OrderedList>
                    <ListItem>Użytkownik zobowiązany jest zwrócić Rzecz w ostatnim dniu wypożyczenia poprzez pozostawienie wypożyczonej Rzeczy w jednym z punktów Dostawcy (firmy kurierskiej odpowiedniej z etykietą) do godziny 13.00 naklejając już zaadresowaną i opłaconą etykietę dostarczoną przez MADLE.</ListItem>
                    <ListItem>Koszty zwrotu wypożyczonej Rzeczy zawierają się w kosztach dostawy wypożyczonej Rzeczy, o której mowa w Dziale VI Regulaminu, chyba że treść umowy wypożyczenia lub szczegółowe postanowienia Regulaminu stanowią inaczej.</ListItem>
                    <ListItem>Zwracaną wypożyczoną Rzecz Użytkownik powinien zapakować w dedykowaną kopertę i pudełko, które zostały dostarczone Użytkownikowi wraz z wypożyczoną Rzeczą.</ListItem>
                    <ListItem>Użytkownik zobowiązany jest do zapłaty na rzecz MADLE kary umownej w wysokości 40,00 zł za każdy dzień opóźnienia w zwrocie wypożyczonej Rzeczy. Postanowienie zawarte w zdaniu powyżej ma zastosowanie również w sytuacji zwrotu wypożyczonej Rzeczy w stanie niekompletnym.</ListItem>
                    <ListItem>Użytkownik zostanie powiadomiony o wysokości naliczonej w oparciu o postanowienia niniejszego punktu Regulaminu kary umownej w drodze wiadomości przesłanej na adres poczty elektronicznej Użytkownika i zobowiązany jest do zapłaty kwoty z tego tytułu podanej w zawiadomieniu, w terminie 7 dni od daty wysłania wiadomości z zawiadomieniem o wysokości naliczonej kary umownej.</ListItem>
                    <ListItem>MADLE jest uprawniony do dochodzenia od Użytkownika odszkodowania przewyższającego wysokość kary umownej należnej zgodnie z postanowieniami niniejszego punktu Regulaminu w przypadku poniesienia szkody o wyższej wartości niż kara umowna.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział X. WYMIANA RZECZY</ListItem>
                <OrderedList>
                    <ListItem>Użytkownik wypożyczający Rzecz na okres 4 lub 5 dni jest uprawniony do wymiany dostarczonej wypożyczonej Rzeczy na inną dostępną w wybranym terminie Rzecz na warunkach opisanych w niniejszym Dziale. Opłatę za wypożyczenie innej wybranej Rzeczy następuje za dopłatą określoną przez MADLE, którą należy wnieść w ciągu 24 godzin i wysłać w tym terminie potwierdzenie dokonanej wpłaty na adres E-mail MADLE. Brak potwierdzenia wysłanego w tym terminie powoduje, że wymiana Rzeczy nie dochodzi do skutku.</ListItem>
                    <ListItem>Użytkownik może dokonać wymiany wypożyczonej Rzeczy pod warunkiem zawiadomienia MADLE w terminie 24 godzin od momentu dostarczenia Rzeczy o chęci dokonania wymiany Rzeczy, składając oświadczenie za pośrednictwem poczty elektronicznej, na adres E-Garderobe podany w Dziale II niniejszego Regulaminu lub telefonicznie.</ListItem>
                    <ListItem>Użytkownik może dokonać wymiany wypożyczonej Rzeczy na inną Rzecz pod warunkiem zwrotu pierwotnie wypożyczonej Rzeczy w stanie niepogorszonym w stosunku do stanu, w jakim ta Rzecz została dostarczona Użytkownikowi, w szczególności kompletnym, nieuszkodzonym, nienoszącym śladów użytkowania, niezabrudzonym, z nienaruszonymi oryginalnymi metkami zabezpieczającymi.</ListItem>
                    <ListItem>Użytkownik zobowiązany jest zwrócić wypożyczoną Rzecz następnego dnia roboczego po dniu wysłania zgłoszenia wymiany wypożyczonej Rzeczy, poprzez pozostawienie wypożyczonej Rzeczy w jednym z punktów Dostawcy do godziny 13.00 naklejając już zaadresowaną i opłaconą etykietę zwrotną dostarczoną przez MADLE.</ListItem>
                    <ListItem>Niezachowanie terminów zwrotu opisanych w punkcie 4 powyżej, powoduje wygaśnięcie uprawnienia do wymiany Rzeczy.</ListItem>
                    <ListItem>Koszty ponownej dostawy wypożyczonej Rzeczy w związku z dokonywaną wymianą, każdorazowo ponosi Użytkownik.</ListItem>
                    <ListItem>W sytuacji istnienia różnicy w cenie wypożyczenia pomiędzy wypożyczoną Rzeczą wybraną pierwotnie, a wypożyczoną Rzeczą nowo wybraną, Użytkownik może być w zależności od sytuacji zobowiązany do uiszczenia dopłaty, bądź uprawniony do uzyskania od MADLE zwrotu nadpłaty.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział XI. REKLAMACJE</ListItem>
                <OrderedList>
                    <ListItem>MADLEjest zobowiązane do dostarczenia Użytkownikowi wypożyczonej Rzeczy wolnej od wad fizycznych i prawnych.</ListItem>
                    <ListItem>MADLE ponosi wobec Użytkownika odpowiedzialność za wady wypożyczonej Rzeczy na zasadach określonych w przepisach ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2018, poz. 1025, z późn. zm.), w szczególności art. 664 Kodeksu cywilnego. W przypadku Użytkowników, którzy dokonują zawarcia umowy wypożyczenia Rzeczy działając jako Przedsiębiorcy odpowiedzialność MADLE z tytułu rękojmi zostaje wyłączona.</ListItem>
                    <ListItem>Użytkownik jest uprawniony do zgłoszenia reklamacji w przypadku nieprawidłowego wykonania umowy wypożyczenia Rzeczy przez MADLE, w szczególności gdy dostarczona wypożyczona Rzecz jest wadliwa lub niezgodna z Zamówieniem.</ListItem>
                    <ListItem>Użytkownik może dokonać zgłoszenia reklamacji pisemnie lub za pośrednictwem poczty elektronicznej MADLE, na adresy podane w Dziale II niniejszego Regulaminu w czasie nie dłuższym niż 24 godziny.</ListItem>
                    <ListItem>W celu jak najszybszego przeprowadzenia procedury reklamacyjnej Użytkownik powinien podać w zgłoszeniu reklamacji dane dotyczące Zamówienia, dane Użytkownika składającego reklamację, zwięzły opis wady wypożyczonej Rzeczy lub innych nieprawidłowości wykonania usługi wypożyczenia, okoliczności (w tym datę) jej wystąpienia, oraz żądania Użytkownika w związku ze zgłaszaną reklamacją.</ListItem>
                    <ListItem>MADLE ustosunkuje się do zgłoszonej reklamacji w terminie 14 dni od daty jej zgłoszenia informując Użytkownika o sposobie rozpatrzenia reklamacji w formie pisemnej lub za pośrednictwem wiadomości przesłanej na adres poczty elektronicznej Użytkownika, w zależności od sposobu zgłoszenia reklamacji przez Użytkownika.</ListItem>
                    <ListItem>Użytkownik może również zgłosić reklamację w związku z świadczeniem przez MADLE nieodpłatnych usług drogą elektroniczną, o których mowa w niniejszym Regulaminie. Reklamacja może być złożona za pośrednictwem poczty elektronicznej na adres MADLE podany w Dziale II niniejszego Regulaminu. W zgłoszeniu reklamacyjnym Użytkownik powinien zwięźle przedstawić powody zgłoszenia reklamacji. MADLE niezwłocznie, lecz nie później niż w terminie 14 dni poinformuje Użytkownika o sposobie rozpatrzenia reklamacji.</ListItem>
                    <ListItem>W przypadku wystąpienia ewentualnych braków zgłoszenia reklamacyjnego dokonanego przez Użytkownika, MADLE zwróci się do Użytkownika o ich uzupełnienie.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział XII. UMOWNE PRAWO ODSTĄPIENIA</ListItem>
                <OrderedList>
                    <ListItem>Użytkownikowi przysługuje prawo odstąpienia od umowy wypożyczenia Rzeczy w tym samym dniu roboczym, w którym Użytkownik otrzymał przesyłkę.</ListItem>
                    <ListItem>Warunkiem skorzystania z powyższego uprawnienia jest zwrot Rzeczy w stanie niepogorszonym w stosunku do stanu, w jakim Rzecz została dostarczona Użytkownikowi, w szczególności kompletnym, nieuszkodzonym, nienoszącym śladów użytkowania, niezabrudzonym, z nienaruszonymi oryginalnymi metkami zabezpieczającymi.</ListItem>
                    <ListItem>Użytkownik odstępuje od umowy wypożyczenia Rzeczy składając oświadczenie za pośrednictwem poczty elektronicznej, na adres MADLE podany w Dziale II niniejszego Regulaminu.</ListItem>
                    <ListItem>Do zachowania terminu na odstąpienie od umowy wystarczy wysłanie oświadczenia przed jego upływem.</ListItem>
                    <ListItem>W przypadku skutecznego odstąpienia, umowę wypożyczenia Rzeczy uważa się za niezawartą.</ListItem>
                    <ListItem>Użytkownik, który odstąpił od umowy zobowiązany jest zwrócić Rzecz w tym samym dniu roboczym, w którym otrzymał przesyłke poprzez dostarczenie przesyłki do wybranego punktu Dostawcy wraz z oświadczeniem o odstąpieniu od umowy.</ListItem>
                    <ListItem>Niezachowanie terminów zwrotu opisanych w punkcie 6 powyżej, powoduje, że oświadczenie woli o odstąpieniu od umowy traktuje się jako niebyłe, zaś umowa wypożyczenia trwa nadal zgodnie z warunkami Zamówienia.</ListItem>
                    <ListItem>Koszty bezpośrednie zwrotu Rzeczy w każdym przypadku ponosi Użytkownik.</ListItem>
                    <ListItem>Zwrot płatności przez MADLE zostanie dokonany tak samo jak w przypadku płatności za wypożyczenie.</ListItem>
                    <ListItem>Użytkownik ponosi odpowiedzialność względem MADLE za zmniejszenie wartości wypożyczonej Rzeczy będące wynikiem korzystania z niej w sposób wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania Rzeczy, w tym w szczególności za uszkodzenie wypożyczenie Rzeczy, zabrudzenie, niekompletność.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział XIII. ZAKRES BEZPŁATNYCH USŁUG ELEKTRONICZNYCH ŚWIADCZONYCH W RAMACH SERWISU</ListItem>
                <OrderedList>
                    <ListItem>MADLE świadczy za pośrednictwem Serwisu następujące bezpłatne usługi elektroniczne:</ListItem>
                    <OrderedList>
                        <ListItem>Formularz Kontaktowy</ListItem>
                        <ListItem>Konto Użytkownika</ListItem>
                    </OrderedList>
                    <ListItem>MADLE świadczy powyższe usługi 7 dni w tygodniu, 24 godziny na dobę.</ListItem>
                    <ListItem>Wszelkie ewentualne zmiany w zakresie zasad świadczenia wskazanych wyżej usług będą dokonywane w sposób właściwy dla procedury zmiany niniejszego Regulaminu opisanej w Dziale XVIII Regulaminu.</ListItem>
                    <ListItem>Usługa Konto Użytkownika polega na udostępnieniu Użytkownikowi indywidualnie przypisanego w ramach Serwisu panelu, w którym są gromadzone dane podane przez Użytkownika w procesie Rejestracji oraz informacje o złożonych przez Niego Zamówieniach.</ListItem>
                    <ListItem>Szczegóły dotyczące świadczenia przez MADLE usługi Konta Użytkownika znajdują się w Dziale IV niniejszego Regulaminu.</ListItem>
                    <ListItem>MADLE przysługuje uprawnienie do zablokowania dostępu do wskazanych w niniejszym punkcie nieodpłatnie świadczonych usług w przypadku stwierdzenia, iż jest to niezbędne dla bezpieczeństwa i prawidłowości funkcjonowania Serwisu, stwierdzenia iż Użytkownik podejmuje nieuprawnione działania, w tym zagrażające prawidłowości i bezpieczeństwu działania Serwisu, sprzeczne z obowiązującymi przepisami prawa, niniejszym Regulaminem, dobrym obyczajami. MADLE powiadomi Użytkownika o zablokowaniu dostępu do świadczonych nieodpłatnie usług elektronicznych, jego powodach oraz przewidywanym czasie blokowania dostępu.</ListItem>
                </OrderedList>
                <Box as="br" />

                <ListItem>Dział XIV. POSTANOWIENIA KOŃCOWE</ListItem>
                <OrderedList>
                    <ListItem>W sprawach nieuregulowanych niniejszym Regulaminem odpowiednie zastosowanie znajdują właściwe przepisy prawa powszechnie obowiązującego.</ListItem>
                    <ListItem>Jeżeli którekolwiek z postanowień niniejszego Regulaminu zostanie uznane za nieważne, nieskuteczne lub niewykonalne w jakimkolwiek zakresie, nie wpłynie to na ważność pozostałych postanowień w nim zawartych.</ListItem>
                    <ListItem>Regulamin nie wyłącza, ani nie ogranicza żadnych uprawnień Użytkownika będącego Konsumentem, które przysługują mu na mocy bezwzględnie obowiązujących przepisów prawa.</ListItem>
                    <ListItem>W przypadku sprzeczności pomiędzy postanowieniami niniejszego Regulaminu, a bezwzględnie obowiązującymi przepisami prawa, pierwszeństwo mają te przepisy.</ListItem>
                    <ListItem>Treść niniejszego Regulaminu jest nieodpłatnie udostępniona Użytkownikowi za pośrednictwem strony internetowej Serwisu i może zostać utrwalona przez Użytkownika poprzez wydrukowanie, zapisanie na nośniku lub pobranie w każdej chwili ze strony Internetowej Serwisu.</ListItem>
                    <ListItem>MADLE może dokonywać zmian niniejszego Regulaminu. MADLE poinformuje Użytkowników o planowanych zmianach poprzez zamieszczenie zaktualizowanej wersji Regulaminu na stronie internetowej Serwisu oraz przesłanie informacji mailowej Użytkownikom posiadającym Konto w Serwisie nie później niż 14 dni przed wejściem w życie zmian. Zmiana Regulaminu wchodzi w życie w terminie 14 dni od dnia opublikowania na stronie internetowej Serwisu informacji o planowanej zmianie Regulaminu. W przypadku braku akceptacji zmian wprowadzonych do Regulaminu, Użytkownik zobowiązany jest niezwłocznie poinformować o tym fakcie MADLE.</ListItem>
                    <ListItem>Zmiany Regulaminu nie mają wpływają na Zamówienia złożone do dnia wejścia w życie zmian Regulaminu. W odniesieniu do tych Zamówień stosowane są postanowienia Regulaminu w wersji dotychczasowej.</ListItem>
                    <ListItem>Regulamin wchodzi w życie z dniem 01.06.2024 i obowiązuje do umów zawartych od tego dnia.</ListItem>
                </OrderedList>



            </OrderedList>
        </Box>
    );
}

export default RentTerms;