
import { Box, Button, IconButton, Center, Container, RadioGroup, Text, Image, Heading, useToast, Stack, Spinner } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useEffect, useState } from 'react';
import { useCart } from '../../context/CartContext';
import { formatPrice } from '../cart/PriceTag';
import ReservationCalendar from './ReservationCalendar';
import 'react-calendar/dist/Calendar.css';


function CustomRadio({ value, children, selectedValue, onChange }) {
    return (
        <Box
            onClick={() => onChange(value)}
            cursor="pointer"
            borderWidth="2px"
            borderRadius='5px'
            width='64px'
            height='32px'
            borderColor={value === selectedValue ? "black.500" : "gold.300"}
            bg={value === selectedValue ? "#ffffff" : '#aaaaaa'}
            _hover={{
                bg: "white",
            }}
            alignContent='center'
            textAlign='center'
        >
            <Text fontSize='sm'>{children}</Text>
        </Box>
    );
}

function Product({ product_id }) {
    const { cartSession, AddItemToCart} = useCart();
    const [error, setError] = useState(null);
    const [addToCartIsLoading, setAddToCartIsLoading] = useState(false);
    const [sizeReservation, setSizeReservation] = useState();
    const [currentSize, setCurrentSize] = useState(null);

    const [showCalendar, setShowCalendar] = useState(false);


    const [image, setImage] = useState({
        url: "",
        index: 0,
    });
    const [product, setProduct] = useState({
        id: 0,
        name: "",
        description: "",
        brand: "",
        product_images: [],
        product_details: [],
        product_sizes: []
    });

    const toast = useToast();

    const handleChangeSize = (value) => {
        setCurrentSize(value);
    }


    const handleImageChange = (imageIndex) => {
        const images_len = product.product_images.length
        if (imageIndex > images_len - 1) {
            setImage({ url: process.env.REACT_APP_API_URI + product.product_images[0].image, index: 0 });
        }
        else if (imageIndex < 0) {
            setImage({ url: process.env.REACT_APP_API_URI + product.product_images[images_len - 1].image, index: images_len - 1 });
        }
        else {
            setImage({ url: process.env.REACT_APP_API_URI + product.product_images[imageIndex].image, index: imageIndex });
        }
    };




    useEffect(() => {
        async function getProduct() {
            try {
                let response = await fetch(process.env.REACT_APP_API_URI + '/product/' + product_id, {
                    method: 'GET'
                });
                let response_reserved = await fetch(process.env.REACT_APP_API_URI + '/product_reservation_status/' + product_id, {
                    method: 'GET'
                });
                if (!response.ok || !response_reserved.ok) {
                    throw new Error("Wystąpił błąd");
                }
                let data = await response.json();
                let data_res = await response_reserved.json();
                setProduct(data);
                setCurrentSize(data.product_sizes[0]);
                setSizeReservation(data_res);
                setImage({ url: process.env.REACT_APP_API_URI + data.product_images[0].image, index: 0 });

            }
            catch (error) {
                toast({
                    title: 'Wystąpił błąd.',
                    description: "Proszę spróbować później.",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            }
        };
        getProduct();
    }, [product_id])

    const addProductToCart = async (idx) => {
        if (currentSize === null) {
            toast({
                title: 'Nieprawidłowy rozmiar.',
                description: "Proszę wybrać rozmiar.",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setAddToCartIsLoading(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ product_id: product.id, sessionId: cartSession, product_details_id: product.product_details[idx].id, product_size: currentSize.id }),
            };
            let response = await fetch(process.env.REACT_APP_API_URI + '/add_product_to_cart/', requestOptions);
            if (!response.ok) {
                const resp_error = await response.json();
                if (resp_error.error) {
                    toast({
                        title: 'Wystąpił błąd.',
                        description: resp_error.error.text,
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                    });
                    setAddToCartIsLoading(false);
                    return;
                }
                throw (new Error());
            }

            setAddToCartIsLoading(false);
            AddItemToCart();
            toast({
                title: 'Dodano produkt.',
                description: "Dodano do koszyka.",
                status: 'success',
                duration: 4000,
                isClosable: true,
            });
        }
        catch (error) {
            setAddToCartIsLoading(false);
            toast({
                title: 'Wystąpił błąd.',
                description: "Proszę spróbować później.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    }


    const handleShowCalendar = () => {
        if (currentSize === null) {
            toast({
                title: 'Nieprawidłowy rozmiar.',
                description: "Proszę wybrać rozmiar.",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setShowCalendar(!showCalendar);
    };

    if (error) {
        return <Center minH='80vh'> <Text>{error.message}</Text></Center>
    }

    let rent_idx = -1;
    let buy_idx = -1;

    for (let i = 0; i < product.product_details.length; i++) {
        if (product.product_details[i].for_rent) {
            rent_idx = i;
        }
        else {
            buy_idx = i;
        }

    }

    const out_of_stock = currentSize && currentSize.stock <= 0 ? true : false;

    return (
        <Box
            maxW={{ base: '3xl', lg: '7xl' }}
            mx="auto"
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '6', md: '8', lg: '12' }}
            minH='70vh'
        >
            <Stack
                direction={{ base: 'column', lg: 'row' }}
                align={{ lg: 'flex-start' }}
                spacing={{ base: '4', md: '8' }}
                alignItems={{ lg: 'center' }}>

                <Container p='2' width={{ base: '90%', md: '65%' }}>
                    <Box display='inline-block' position='relative'>
                        <Image src={image.url}
                            borderRadius='lg'
                            alt='image'
                        />
                        <IconButton
                            icon={<ChevronLeftIcon />}
                            aria-label="Previous Image"
                            position="absolute"
                            left="5px"
                            top="50%"
                            transform="translateY(-50%)"
                            size="sm"
                            onClick={() => handleImageChange(image.index - 1)} // Change to the previous image
                        />

                        <IconButton
                            icon={<ChevronRightIcon />}
                            aria-label="Next Image"
                            position="absolute"
                            right="5px"
                            top="50%"
                            transform="translateY(-50%)"
                            size="sm"
                            onClick={() => handleImageChange(image.index + 1)} // Change to the next image
                        />
                    </Box>
                </Container>

                <Stack direction="column" spacing={{ base: '3', md: '6' }} width={{  md: '35%' }} alignContent='center' alignItems='center'>

                    <Heading fontSize="5xl" fontWeight="extrabold">{product.name}</Heading>
                    <Text fontSize='lg' fontWeight="600" > {product.description} </Text>
                    <Stack direction='row' alignItems='center' spacing={{ base: '3', md: '6' }}>
                        <Text fontSize='lg' fontWeight="600" >Rozmiar</Text>
                        <RadioGroup onChange={handleChangeSize} value={currentSize}>
                            <Stack direction="row" spacing='2'>
                                {
                                    product.product_sizes.map((size, _) => 
                                        (<CustomRadio key={size.id} value={size} selectedValue={currentSize} onChange={handleChangeSize}> {size.size}</CustomRadio>))
                                }
                            </Stack>
                        </RadioGroup>
                    </Stack>

                    <Text fontSize='lg' fontWeight="bold" alignSelf=''> {product.brand} </Text>


                    <Stack direction={{ base: 'column', lg: 'row' }} spacing='5'>

                        {buy_idx !== -1 &&
                            <Stack direction='column' textAlign='center' spacing='6'>
                                <Text fontWeight="600" >Kup za {formatPrice(product.product_details[buy_idx].price)}</Text>
                                {addToCartIsLoading ? <Spinner /> :
                                    !out_of_stock && !sizeReservation[currentSize.id] ?
                                    <Button variant='solid' bgColor='#ffffff' textColor='black' onClick={() => addProductToCart(buy_idx)} boxShadow='2px 2px 8px 0 rgba(0,0,0,0.2)'>
                                        Dodaj do koszyka
                                    </Button>
                                    :
                                    <Text fontWeight="600" color='red.600'>Produkt w rezerwacji</Text>
                                    
                                }
                            </Stack>
                        }


                        {rent_idx !== -1 &&
                            <Stack direction='column' textAlign='center' spacing='6'>
                                <Text fontWeight="600" >Wypożycz za {formatPrice(product.product_details[rent_idx].price)} </Text>

                                {!out_of_stock &&
                                    <Button variant='solid' bgColor='#ffffff' textColor='black' onClick={handleShowCalendar} boxShadow='2px 2px 8px 0 rgba(0,0,0,0.2)'>
                                        Zarezerwuj
                                    </Button>
                                }
                            </Stack>
                        }



                    </Stack>

                    {showCalendar && <ReservationCalendar product_details_id={product.product_details[rent_idx].id} product_size={currentSize} />}
                    {out_of_stock && <Text fontWeight="medium" color='red.600'>Produkt niedostępny</Text>}
                </Stack>
            </Stack>

        </Box>
    );
}

export default Product;