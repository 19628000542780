import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductCard from "./ProductCard";
import { Box, Center, Container } from "@chakra-ui/react";
import { GetBuyPrice } from "./CardManager";


export const responsiveCarouselBreakpoints = {
    desktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 4
    },
    tabletExt: {
        breakpoint: { max: 1023, min: 765 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 764, min: 465 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function MultiCarousel({ products }) {

    const productList = products.map((item, key) => (
        <ProductCard key={key} product={item} price={GetBuyPrice(item)}/>
    ));

    return (
        <Box width='100%' alignContent='center' justifyContent='center' alignSelf='center' justifySelf='center'>
            <Carousel responsive={responsiveCarouselBreakpoints} showDots={false}
                autoPlay={true} removeArrowOnDeviceType={["desktop", "tabletExt", "tablet", "mobile"]}
                autoPlaySpeed={3000} infinite={true}>
                {productList}
            </Carousel>
        </Box>
    );

}

export default MultiCarousel;