import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import ProductCard from './ProductCard';

export function GetBuyPrice(product) {
    for (let i = 0; i < product.product_details.length; i++) {
        if (!product.product_details[i].for_rent) {
            return product.product_details[i].price;
        }
    }

    return product.product_details[0].price;
}

export function GetRentPrice(product) {
    for (let i = 0; i < product.product_details.length; i++) {
        if (product.product_details[i].for_rent) {
            return product.product_details[i].price;
        }
    }

    return product.product_details[0].price;
}

function CardManager({ products, rent_view }) {

    if(products.length === 0) {
        return (
            <Box height='75vh' alignContent='center'>
                <Heading textAlign='center'> Brak produktów </Heading>
            </Box>
        );
    }


    return (
        <SimpleGrid columns={{ base: 1, md: 3, xl: 5 }} spacing={2}>

            {
                products.map((item, _) => (
                    <Box key={item.id} alignContent='center' justifyContent='center' alignSelf='center' justifySelf='center' m={2}
                    >
                        <ProductCard product={item} price={rent_view ? GetRentPrice(item) : GetBuyPrice(item)} />
                    </Box>

                ))
            }

        </SimpleGrid >
    );


}

export default CardManager;