import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `'AcademyEngravedLetPlain', sans-serif`,
    body: `'AcademyEngravedLetPlain', sans-serif`,
  },
  styles: {
    global: {
      body: {
        bg: 'grey.50'
      },
      '@font-face': {
        fontFamily: 'AcademyEngravedLetPlain',
        src: `url('/fonts/AcademyEngravedLetPlain.ttf') format('truetype')`,
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
    },
  },
});


export default theme;