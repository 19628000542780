import { useEffect, useState } from 'react';

const useGetCategories = () => {
    const [categories, setCategories] = useState(null);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getCategories = async () => {
            try {
                let response = await fetch(process.env.REACT_APP_API_URI + '/get_categories/', {method: 'GET'});
                if (!response.ok) {
                    setError(true);
                }
                else {
                    let response_data = await response.json();
                    setCategories(response_data);
                    setIsLoading(false);
                }
            }
            catch (error) {
            }
        }

        getCategories();
    }, []);

    return { categories, error, isLoading };
};

export default useGetCategories;