import { Box, Container, Stack, Text, Image, IconButton } from '@chakra-ui/react';
import Follow from './Follow';
import { useNavigate } from 'react-router-dom';


function Footer() {
    const navigate = useNavigate();
    return (
        <Box bg="#ffffff" py={4} mt='2' boxShadow='0 -4px 8px 0 rgba(0,0,0,0.2)'>
            <Container maxW="container.xl" textAlign='center'>
                <Stack direction='column' spacing={2} justifyContent='space-evenly'>
                    <Stack direction={{ base: 'column', md: 'row'}} alignContent='center' justifyContent='space-between' textAlign='center' mb={2}>
                        <Text color='black' fontSize='medium' onClick={() => navigate(`/privacy_policy`)} _hover={{cursor: 'pointer'}}>Polityka prywatności</Text>
                        <Text color='black' fontSize='medium' onClick={() => navigate(`/purchase_terms`)} _hover={{cursor: 'pointer'}}>Regulamin sprzedaży</Text>
                        <Text color='black' fontSize='medium' onClick={() => navigate(`/rent_terms`)} _hover={{cursor: 'pointer'}}>Regulamin wypożyczania</Text>
                        <Text color='black' fontSize='medium' onClick={() => navigate(`/complain`)} _hover={{cursor: 'pointer'}}>Reklamacja</Text>
                    </Stack>
                    <Follow />
                </Stack>
                {/* <Text color='#f8cd08'>{new Date().getFullYear()} &copy; All rights reserved. Version {process.env.REACT_APP_VERSION}</Text> */}
            </Container>
        </Box>

    );
}

export default Footer;