import { Text, IconButton, Link, useMediaQuery, Box, Flex, Image, Stack } from "@chakra-ui/react";

function Follow() {
    const [isLargeScreen] = useMediaQuery("(min-width: 768px)");
    const iconSize = isLargeScreen ? "36px" : "24px";


    return (
            <Stack direction={{ base: 'column', md: "row" }} alignContent='center' justifyContent='space-evenly' justifyItems='center'>
                <Box>
                    <Stack direction={{ base: 'column', md: "row" }} alignContent='center' textAlign='center' justifyItems='center'>
                        <IconButton
                            icon={<Image src='phone.png' alt="phone"
                                width={{ base: '24px', md: '36px' }} height={{ base: '24px', md: '36px' }} />}
                            isRound="true" size='lg' bgColor='#ffffff' />
                        <Text color='black' fontSize='medium' alignSelf='center'>781 526 508</Text>

                    </Stack>
                </Box>
                <Box ml={3}>
                    <Stack direction={{ base: 'column', md: "row" }} alignContent='center' justifyContent='center'>
                        <Link href="https://www.facebook.com/share/Y4AQopCMkaxKjEjQ/" isExternal='true'>
                            <IconButton
                                icon={<Image src='facebook_icon.png' alt="Facebook"
                                    style={{ width: iconSize, height: iconSize }} />}
                                isRound="true" alignSelf='flex-end' size='lg' bgColor='#ffffff' />
                        </Link>

                        <Link href="https://www.instagram.com/__madle__?igsh=MTRubTZhYzB2dDdoaw==" isExternal='true'>
                            <IconButton
                                icon={<Image src='instagram_icon.png' alt="Instagram"
                                    style={{ width: iconSize, height: iconSize }} />}
                                isRound="true" alignSelf='flex-end' size='lg'
                                bgColor='#ffffff' />
                        </Link>

                        <Link href="https://www.tiktok.com/@c.madle?_t=8pFsyKB6Ir2&_r=1" isExternal='true'>
                            <IconButton
                                icon={<Image src='tiktok_icon.png' alt="Tiktok"
                                    style={{ width: iconSize, height: iconSize }} />}
                                isRound="true" size='lg' bgColor='#ffffff' />
                        </Link>
                    </Stack>
                </Box>
            </Stack>

    );
}

export default Follow;