import { Box, Center, Heading, Spinner, Text, Stack } from '@chakra-ui/react'
import CardManager from '../cards/CardManager';
import Carousel from '../cards/Carousel';
import { useEffect, useState } from 'react';
import MultiCarousel from '../cards/MultiCarousel';


function News() {
    const [products, setProducts] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getLatestProducts() {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ all: true, limit: 10 })
                };

                let response = await fetch(process.env.REACT_APP_API_URI + '/products/', requestOptions);
                if (!response.ok) {
                    throw new Error("Wystąpił błąd");
                }

                let response_data = await response.json();
                setProducts(response_data);
                setIsLoading(false);
            }
            catch (error) {
                return;
            }
        }

        getLatestProducts();
    }, []);

    if (isLoading) {
        return (<Center minH='80vh' m='3'><Spinner /></Center>);
    }


    return (
        <Center textAlign='center'
            borderColor='#f5f5f5'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            alignSelf='center'
            p={{base: 2, md: 4}}
            m={{base: 2, md: 6}}
        >
            <Stack width='100%' justifyContent='center' alignItems='center' spacing={3}>
                <Box
                    mb='40px'
                    fontSize='200%'
                    textAlign='center'>
                    <Heading as='h2'>Nowości</Heading>
                </Box>
                {/* <Carousel products={products} /> */}

                <MultiCarousel products={products}/>
            </Stack>
        </Center>

    );
}

export default News;