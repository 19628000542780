import {Stack, Spinner, Center, Text} from '@chakra-ui/react';
import CardManager from '../cards/CardManager';
import CategoryCarousel from '../cards/CategoryCarousel';
import useGetProductsForCategory from '../../hooks/useGetProductForCategory';
import { useState } from 'react';
import PaginationCaroulser from '../cards/PaginationCarousel';

function Rent() {
    const requested_products = 20;
    const rent_view = true;
    const [currentCategory, setCurrentCategory] = useState(0);
    const [getAllProducts, setGetAllProducts] = useState(true);
    const [selectedPage, setSelectedPage] = useState(1);
    const {products, error, isLoading, allPages} = useGetProductsForCategory(currentCategory, rent_view, getAllProducts, selectedPage, requested_products);
    
    if (error ) {
        return (<Center m='3'><Text>Nie udało się pobrać danych</Text></Center>);
    }

    if (isLoading) {
        return (<Center m='3'><Spinner /></Center>);
    }
    const onCategoryChange = (id) => {
        if(id !== currentCategory) {
            setCurrentCategory(id);
            setGetAllProducts(false);
            return;
        }

        setCurrentCategory(0);
        setGetAllProducts(true);
    }

    const onSelectedPageChange = (new_page) => {
        if(new_page !== selectedPage) {
            setSelectedPage(new_page);
        }
    }

    return(
        <Stack textAlign='center' direction={'column'}>
            <CategoryCarousel selectedCategory={currentCategory} onCategoryChange={onCategoryChange}/>
            <CardManager products={products} rent_view={true}/>
            <PaginationCaroulser pages={allPages} selectedPage={selectedPage} onPageChange={onSelectedPageChange} />
        </Stack>
    );
}

export default Rent;