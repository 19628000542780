import {
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { formatPrice } from './PriceTag'
import { useNavigate } from 'react-router-dom';


const OrderSummaryItem = (props) => {
  const { label, value, children } = props
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" >
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  )
}

function CartOrderSummary({ totalPrice }) {
  const navigate = useNavigate();

  return (
    <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full">
      <Heading size="md">Podsumowanie</Heading>

      <Stack spacing="6">
        {/* <OrderSummaryItem label="Wartość koszyka" value={formatPrice(totalPrice)} /> */}
        {/* <OrderSummaryItem label="Shipping + Tax">
            <Link href="#" textDecor="underline">
              Calculate shipping
            </Link>
          </OrderSummaryItem> */}
        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Razem
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(totalPrice)}
          </Text>
        </Flex>
      </Stack>
      <Button size="lg" fontSize="md" bgColor='#ffffff' textColor='black' rightIcon={<FaArrowRight />} boxShadow='2px 2px 8px 0 rgba(0,0,0,0.2)' onClick={() => navigate(`/checkout`)}>
        Przejdź do dostawy
      </Button>
    </Stack>
  )
}

export default CartOrderSummary;