
import { useParams } from 'react-router-dom';
import Product from './Product';

function ProductSale() {
    const { product_id } = useParams();
    return (
        <Product product_id={product_id} />
    );
}

export default ProductSale;