import { Box, Container, Image, Flex, Text, IconButton, Stack, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useCart } from "../../context/CartContext";

function MenuLargeScreen() {

    const { cartItems } = useCart();

    return (
        <Stack alignItems='center' direction='row'>
            <Container width='25%'>
                <Link to="/">
                    <Image src='./logo_nav.png' alt="Logo" alignSelf='flex-end' />
                </Link>
            </Container>

            <Stack justifyContent='space-evenly' width='75%' alignItems='center' direction='row' ml='8'>
                <Container>
                    <Link to="/buy">
                        <Text as='h2' color='black' fontSize='x-large' fontWeight='bold'>Kup</Text>
                    </Link>
                </Container>

                <Container>
                    <Link to="/rent">
                        <Text as='h2' color='black' fontSize='x-large' fontWeight='bold'>Wypożycz</Text>
                    </Link>
                </Container>

                <Container position='relative'>
                    <Link to="/cart">
                        <IconButton
                            icon={<Image src='basket.png' alt="basket"
                                style={{ width: '64px', height: '64px' }} />}
                            isRound="true" alignSelf='flex-end' size='lg' margin='2' bgColor='#ffffff' />


                        {cartItems > 0 && <Box position='absolute' top='0' left='64px' bg='red' borderRadius='full' display='flex' alignItems='center' justifyContent='center' color='black'
                            width='20px' height='20px'>{cartItems}</Box>}


                    </Link>
                </Container>
            </Stack>
        </Stack>
    );
}

export default MenuLargeScreen;