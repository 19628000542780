import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Text, Flex } from "@chakra-ui/react";


function PaginationCaroulser({ pages, selectedPage, onPageChange }) {

    const categoryCarouselBreakpoints = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: pages > 10 ? 10 : pages
        },
        tabletExt: {
            breakpoint: { max: 1023, min: 765 },
            items: pages > 7 ? 7 : pages
        },
        mobile: {
            breakpoint: { max: 764, min: 0 },
            items: pages > 5 ? 5 : pages
        }
    };

    const pagesArray = Array.from({length: pages}, (_, i) => i + 1);
    const pageItem = pagesArray.map((item) => (
        <Flex key={item} justifyContent='center'>
            <Box
                p={4}
                m={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                _hover={{cursor:'pointer'}}

                onClick={() => onPageChange(item)}
            >
                <Text textAlign="center" textDecoration={item === selectedPage ? 'underline' : 'none'} fontWeight='bold'>{item}</Text>
            </Box>
        </Flex>
    ));

    return (
        <Box width='50%' alignContent='center' justifyContent='center' alignSelf='center' justifySelf='center'
        boxShadow="0px 3px 10px rgba(0, 0, 0, 0.1)">
            <Carousel responsive={categoryCarouselBreakpoints}
                autoPlay={false} infinite={false}>
                {pageItem}
            </Carousel>
        </Box>
    );

}


export default PaginationCaroulser;