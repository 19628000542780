import { Box, Text, Heading, Center, Spinner, useToast, Flex, Stack, Link, HStack, useColorModeValue as mode } from '@chakra-ui/react';
import { useCart } from '../../context/CartContext';
import CartItem from '../cart/CartItem';
import CartOrderSummary from '../cart/CartOrderSummary ';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Cart() {
    const [cartItems, setCartItems] = useState(null);
    const [itemRemovedTrigger, setItemRemovedTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { cartSession, RemoveItemFromCart, csrfCookie } = useCart();
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        async function getCart() {
            setIsLoading(true);
            try {
                const requestOptions = {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfCookie},
                    body: JSON.stringify({ sessionId: cartSession }),
                };

                let response = await fetch(process.env.REACT_APP_API_URI + '/cart/', requestOptions);
                if (!response.ok) {
                    throw new Error("Wystąpił błąd");
                }

                let data = await response.json();

                setCartItems(data);
                setIsLoading(false);
            }
            catch (error) {
              console.log(error)
                setError(error)
                setIsLoading(false);
                toast({
                    title: 'Wystąpił błąd.',
                    description: "Nie udało się pobrać danych.",
                    status: 'error',
                    duration: 10000,
                    isClosable: true,
                });
            }
        }

        getCart();
    }, [itemRemovedTrigger]);

    const triggerReloadCart = () => {
        setItemRemovedTrigger(!itemRemovedTrigger);
    }

    if (error) {
        return (<Center m='3'><Text>Nie udało się pobrać danych</Text></Center>);
    }

    if (isLoading) {
        return (<Center m='3'><Spinner /></Center>);
    }

    const isCartEmpty = cartItems?.length <= 0;

    if (isCartEmpty) {
        return (
            <Box height='80vh' alignContent='center'>
                <Heading textAlign='center'> Koszyk jest pusty </Heading>
            </Box>
        );
    }

    let totalPrice = cartItems.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.quantity * currentValue.product_details.price;
    }, 0);

    return (
        <Box
        maxW={{ base: '3xl', lg: '7xl' }}
        mx="auto"
        px={{ base: '4', md: '8', lg: '12' }}
        py={{ base: '6', md: '8', lg: '12' }}
        minH='80vh'
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          align={{ lg: 'flex-start' }}
          spacing={{ base: '8', md: '16' }}
        >
          <Stack spacing={{ base: '8', md: '10' }} flex="2">
            <Heading fontSize="2xl" fontWeight="extrabold">
              Koszyk ({cartItems.length})
            </Heading>
    
            <Stack spacing="6">
              {cartItems.map((item) => (
                <CartItem key={item.id} cartItem={item} reloadCart={triggerReloadCart} removeFromCart={()=>RemoveItemFromCart()}/>
              ))}
            </Stack>
          </Stack>
    
          <Flex direction="column" align="center" flex="1">
            <CartOrderSummary totalPrice={totalPrice}/>
            <HStack mt="6" fontWeight="semibold">
              <p>lub</p>
              <Link color={mode('blue.500', 'blue.200')} onClick={() => navigate(`/buy`)}>Kontynuuj zakupy</Link>
            </HStack>
          </Flex>
        </Stack>
      </Box>
    );
}

export default Cart;