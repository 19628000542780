import { ChakraProvider, Box, Flex, Text, Icon } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';



function Cancel() {
    return(
        <Box minHeight="100vh" display="flex" justifyContent="center" alignItems="center" >
        <Flex direction="column" alignItems="center" p={10} bg="white" borderRadius="md" boxShadow="lg">
          <Icon as={CloseIcon} w={12} h={12} color="red.500" mb={4} />
          <Text fontSize="2xl" fontWeight="bold" mb={2}>
            Płatność anulowana.
          </Text>
          <Text fontSize="md" textAlign="center">
            Twoja płatność nie została zatwierdzona. Proszę spróbować ponownie.
          </Text>
        </Flex>
      </Box>
    );
}

export default Cancel;