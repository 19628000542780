import { Box, List, ListItem, OrderedList, UnorderedList, Text } from "@chakra-ui/react";


function Complain() {

    return (
        <Box m={3} fontWeight='bold'>
            <Text>ZWROT (ODSTĄPIENIE OD UMOWY)</Text>
            <Box as="br" />
            <Text>Klient ma prawo odstąpić od umowy sprzedaży zakupionych w sklepie MADLE produktów w ciągu 14 dni od ich otrzymania, na zasadach opisanych w Ustawie z dnia 30.05.2014r. o prawach konsumenta (Dz. U. z 2014 nr 827)</Text>
            <Box as="br" />
            <Text>W przypadku odstąpienia od umowy sprzedaży otrzymany produkt należy odesłać na adres:</Text>
            <Box as="br" />
            <Text>MADLE Monika Cichoń, Zaczarnie 312, 33-140 Lisia Góra - Nr tel. 781526508</Text>
            <Box as="br" />
            <Text>Wraz z paragonem i wypełnionym formularzem dołączonym do paczki.</Text>
            <Box as="br" />
            <UnorderedList>
                <ListItem>Nie przyjmujemy paczek wysłanych do nas za pobraniem lub paczkomatami.</ListItem>
                <ListItem>Każdy zwracany produkt powinien być odpowiednio zapakowany oraz zabezpieczony;</ListItem>
                <ListItem>Koszt odesłania towaru pokrywa Kupujący.</ListItem>
                <ListItem>Zwroty kosztów transakcji realizowane są w ciągu 14 dni od momentu otrzymania zwrotu.</ListItem>
                <ListItem>Nie przyjmujemy zwrotów, gdzie towar został uszkodzony, zabrudzony oraz nie ma oryginalnych metek. Zwracany towar musi być nienaruszony.</ListItem>
            </UnorderedList>
            <Box as="br" />

            <Text>Zamówienia opłacone przez: - Przelewy24 zwracamy przez Przelewy24.</Text>
            <Box as="br" />

            <Text>Nie realizujemy zwrotów kosztów transakcji na zagraniczne numery kont bankowych.</Text>
            <Box as="br" />
            <Box as="br" />

            <Text>REKLAMACJA</Text>
            <Box as="br" />

            <Text>Jeżeli otrzymałaś/eś towar zgodny z zamówieniem, jednak rozmiar nie pasuje zastosowanie ma procedura zwrotu. Jeżeli otrzymałaś/eś towar niezgodny z zamówieniem niezwłocznie skontaktuj się z nami a my zlecimy odbiór paczki (zlecenie odbioru opłacane jest przez naszą firmę).</Text>
            <Box as="br" />
            <UnorderedList>
                <ListItem>W przypadku reklamacji otrzymanego towaru otrzymany produkt należy odesłać na adres:</ListItem>
                <ListItem>MADLE Monika Cichoń, Zaczarnie 312, 33-140 Lisia Góra - Nr tel. 781526508</ListItem>
                <ListItem>Wraz z paragonem i wypełnionym formularzem dołączonym do paczki.</ListItem>
                <ListItem>Nie przyjmujemy paczek wysłanych do nas za pobraniem lub paczkomatami.</ListItem>
                <ListItem>Każdy reklamowany produkt powinien być czysty, odpowiednio zapakowany oraz zabezpieczony. W przypadku uzasadnionej reklamacji, towar uszkodzony zostanie naprawiony lub wymieniony na taki sam produkt, ale pełnowartościowy, a jeśli będzie to niemożliwe, sklep  zwróci wszelkie poniesione przez Klienta koszty transakcji.</ListItem>
                <ListItem>Zwroty kosztów transakcji realizowane są przelewem bankowym.</ListItem>
                <ListItem>Zamówienia opłacone przez: - Przelewy24 zwracamy przez Przelewy24.</ListItem>
            </UnorderedList>
            <Box as="br" />

            <Text>Nie realizujemy zwrotów kosztów transakcji na zagraniczne numery kont bankowych.</Text>
            <Box as="br" />
            
            <Text>Sklep MADLE ustosunkuje się do każdego żądania niezwłocznie, ale nie później niż w terminie 14 dni. Reklamacje rozpatrywane są zgodnie z przepisami Ustawy z dnia 23.04.1964r. Kodeks cywilny.</Text>
        </Box>
    );
}

export default Complain;