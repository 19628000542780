import { createContext, useState, useContext, useEffect } from "react";
import useCrsfCookie from "../hooks/useCrsfCookie";

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
}



export const CartProvider = ({ children }) => {
    const [cartSession, setCartSession] = useState("");
    const [csrfCookie, setCsrfCookie] = useState("");
    const [cartItems, setCartItems] = useState(0);
    
    useEffect(() => {
        const getSession = async () => {
            try {
                let response = await fetch(process.env.REACT_APP_API_URI + '/init_session/', {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!response.ok) {
                    return;
                }

    
                let data = await response.json();
                setCartSession(data.sessionId);

                const requestOptions = {
                    method: 'GET',
                    credentials: 'include'
                };
                let responseCookie = await fetch(process.env.REACT_APP_API_URI + '/csrf/', requestOptions);

                if (!responseCookie.ok) {
                    return;
                }

                setCsrfCookie(responseCookie.headers.get("X-CSRFToken"));

                const requestOptionsCart = {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfCookie},
                    body: JSON.stringify({ sessionId: cartSession }),
                };

                let responseCart = await fetch(process.env.REACT_APP_API_URI + '/cart/', requestOptionsCart);
                if (!response.ok) {
                    return;
                }

                let dataCart = await responseCart.json();

                setCartItems(dataCart.length);
            }
            catch (error) {
                console.log(error);
            }
        }

        getSession();

    }, [])


    const AddItemToCart = () => {
        setCartItems(cartItems + 1);
    }

    const RemoveItemFromCart = () => {
        if(cartItems === 0){
            return;
        }
        setCartItems(cartItems - 1);
    }

    return (
        <CartContext.Provider value={{ cartSession, cartItems, AddItemToCart, RemoveItemFromCart, csrfCookie }}>
            {children}
        </CartContext.Provider>
    );
}