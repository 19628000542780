import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/footer/Footer';
import Menu from './components/menu/Menu';
import News from './components/views/News';
import Rent from './components/views/Rent';
import Buy from './components/views/Buy';
import Cart from './components/views/Cart';
import { CartProvider } from './context/CartContext';
import ProductSale from './components/views/ProductSale';
import Checkout from './components/checkout/Checkout';
import Success from './components/checkout/Success';
import Cancel from './components/checkout/Cancel';
import HomePage from './components/test';
import PrivacyPolicy from './components/footer/Policies/PrivacyPolicy';
import PurchaseTerms from './components/footer/Policies/PurchaseTerms';
import RentTerms from './components/footer/Policies/RentTerms';
import Complain from './components/footer/Policies/Complain';

function App() {
  return (
    <CartProvider>
      <BrowserRouter>
        <Menu />
        
        <Routes>
          <Route index element={<News />} />
          <Route path='/rent' element={<Rent />} />
          <Route path='/buy' element={<Buy />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/product/:product_id' element={<ProductSale/>}/>
          <Route path='/checkout' element={<Checkout/>}/>
          <Route path='/success' element={<Success/>}/>
          <Route path='/cancel' element={<Cancel/>}/>
          <Route path='/privacy_policy' element={<PrivacyPolicy/>}/>
          <Route path='/purchase_terms' element={<PurchaseTerms/>}/>
          <Route path='/rent_terms' element={<RentTerms/>}/>
          <Route path='/complain' element={<Complain/>}/>
        </Routes>

        <Footer />
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;
